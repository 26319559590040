import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Observer, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { Store } from '@ngrx/store';
import { StateState } from '@/store/state/state';
import { AppState } from '@/store/state';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateSelectedNewsId } from '@/store/state/actions';

@Component({
  selector: 'app-news-related',
  templateUrl: './news-related.component.html',
  styleUrls: ['./news-related.component.scss']
})
export class NewsRelatedComponent implements OnInit {
  private state$: Observable<any>;
  private newsId: Number = null;
  newsObj;
  relatedNews: any[];

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private router: Router,
      private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    var id = this.route.snapshot.paramMap.get('id');
    // console.log(id);
    if(id != null) {
      this.newsId = parseInt(id);
      this.loadRelatedNews();
    }
    this.store.select('state').subscribe((n) => {
      if(n != null && n.newsId != null) {
        this.newsId = n.newsId;
        this.loadRelatedNews();
      }
    })
  }

  async ngAfterViewInit() {
    await new Promise(resolve => { setTimeout(() => { resolve(0); }, 100); });
  }

  loadRelatedNews() {
    this.appService.dataGet("news", {
      key: "id",
      value: this.newsId.toString()
    }).then((result) => {
      if(result.payload.results.length > 0) {
        this.newsObj = result.payload.results.pop();
        this.appService.dataGet("news", null, [{
            key: 'tags',
            value: [this.newsObj.tags]
          }],
          {
            key: 'id',
            value: this.newsObj.id
          }, null, 'date'
        ).then((result2) => {
          this.relatedNews = result2.payload.results;
        });
      }
    })
  }

  loadNews() {
  }

  onClick(news) {
    this.store.dispatch(new UpdateSelectedNewsId(news.id));
    this.router.navigateByUrl('/news/' + news.id);
  }

  ngOnDestroy(): void {
  }

}
