<div class="main-wrapper">
  <h4 class="pt-2">我的計劃</h4>
  <div>"我的計劃"功能會幫你記低計劃資料，方便查閱</div>
  <div *ngFor="let n of plans; let i = index" style="text-align: left">
    <div class="news-item mt-2" style="margin: 0" (click)="onPlanSelect(n)" style="color: white; position: relative; max-width: 400px; border: 1px solid black; border-radius: 1rem; margin: auto;}">
      <div class="pl-2" style="border-radius: 1rem 1rem 0px 0px;" [style]="{'background-color': (n.related.providers && n.related.providers.length > 0 ? n.related.providers[0].color_code : 'red'), 'color': 'white', 'position': 'relative'}">{{ n.related.providers && n.related.providers.length > 0 ?n.related.providers[0].name : '' }}</div>
      <div class="pl-2 pr-2 pb-1" style="background: rgb(0,0,0); background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); border-radius: 0px 0px 1rem 1rem;">
        <div class="mb-0 pt-1 title">{{ n.title }}</div>
        <div class="tagline" *ngIf="n.start_date">登記日期: {{ n.start_date.split(' ')[0] }}</div>
        <div class="tagline" *ngIf="n.end_date">到期日期: {{ n.end_date.split(' ')[0] }}</div>
        <div class="tagline" *ngIf="n.cost">月費: ${{ n.cost }}</div>
      </div>
    </div>
  </div>
  <hr />
  <div class="mb-2"><app-button (click)="onAddClick()">新增已有計劃</app-button></div>
  <div><app-button [color]="'info'" (click)="onBackClick()">返回</app-button></div>
</div>
