import {Component, Input} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { Subscription } from 'rxjs';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-hiddenfield',
    templateUrl: './hiddenfield.component.html',
    styleUrls: ['./hiddenfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormHiddenFieldComponent extends Field {
    config: FieldConfig;
    group: UntypedFormGroup;

    prevVal;

    valueChangeSubscribe: Subscription;

    ngOnInit() {
        this.form.addField(this);
        if(this.config.onValueChanges) {
          this.valueChangeSubscribe = this.form.form.controls[this.config.name].valueChanges.subscribe((v) => {
            if(v !== this.prevVal) {
              this.prevVal = v;
              this.config.onValueChanges(v, this.form, this);
            }
          });
        }
    }

    ngOnDestroy() {
      if(this.valueChangeSubscribe) { this.valueChangeSubscribe.unsubscribe(); }
    }
}
