<div class="main-wrapper">
  <h4 class="pt-2">你的帳戶</h4>

  <div>
    <img style="width: 100px; height: 100px; border: 1px solid black; border-radius: 50px;" [src]="appService.user.thumbnail" />
  </div>
  <div class="mb-5">
    {{ appService.user.name }}
  </div>

  <div class="mt-1"><app-button (click)="onPageClick('plans')">我的計劃</app-button></div>
  <div class="mt-1"><app-button (click)="onPageClick('stars/fixedwired')">我的收藏</app-button></div>
  <div class="mt-1"><app-button (click)="onPageClick('notifications')">最新通知</app-button></div>
  <div class="mt-1"><app-button (click)="onPageClick('info')">帳戶設定</app-button></div>
  <hr />

  <div><app-button [color]="'danger'" class="mb-5" (click)="logout()">登出</app-button></div>
</div>
