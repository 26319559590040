<div class="row main-wrapper m-0" style="height: 100%">
  <div class="main col p-0" style="height: 100%;">
    <section class="content" style="height: 100%; padding: 0">
      <div class="container-fluid" style="height: 100%; padding: 0; position: relative" *ngIf="planId != null && planObj != undefined">
        <div style="height: calc(100% - 1rem - 4rem - 1rem - 1rem - 1.5rem); overflow-y: scroll;" [style]="{'height': 'calc(100vh - 3rem - 1.25rem - ' + bottomBarBottom + ')'}">
          <div [style]="{'background-color': planObj.related.providers ? planObj.related.providers[0].color_code : 'black', 'color': 'white'}" class="p-2 pl-4">{{ planObj.related.providers ? planObj.related.providers[0].name : '' }}</div>
          <img *ngIf="planObj.thumbnail" src="{{ planObj.thumbnail }}" style="width: 100%;" />
          <div class="ml-2 mr-2" style="margin-bottom: 8rem">
            <div style="margin-top: 100px; border: 1px solid blue; border-radius: 10px; background-color: rgb(238, 238, 238)">
              <div style="border: 1px solid black; border-radius: 200px; width: 150px; height: 150px; background-image: url('{{ relatedSales && relatedSales.thumbnail ? relatedSales.thumbnail : salesicon }}'); background-size: cover; background-position: center; margin: auto; margin-top: -80px;"></div>
              <div style="text-align: center;" class="mt-2 mb-2">{{ relatedSales ? relatedSales.name : "" }}</div>
              <div *ngIf="relatedSales && relatedSales.show_cross_plan" class="m-2 max-1240">
                <div>此銷售員其他計劃</div>
                <div style="display: flex; overflow-x: scroll;" class="related-plans-horiztal-holder">
                  <div *ngFor="let plan of relatedPlans" class="m-2" (click)="onClickRelatedPlan(plan)" style="display: inline-block; max-width: 48%; cursor: pointer; min-width: 200px;">
                    <img src="{{ plan.thumbnail }}" style="width: 100%" />
                    <div style="color: grey">#{{ plan.tags }}</div>
                    {{ plan.title }}
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 p-2" style="border: 1px solid blue; border-radius: 10px; background-color: rgb(238, 238, 238)">
              <div style="text-align: center;"><div style="display: inline-block; margin: auto; font-size: 150%; border-bottom: 2px solid blue"><h2>計劃簡介</h2></div></div>
              <h1 class="mb-0 pt-1 title">{{ planObj.title }}</h1>
              <h2 class="tagline">- {{ planObj.tagline1 }}</h2>
              <h2 class="tagline">- {{ planObj.tagline2 }}</h2>
              <h2 class="tagline">- {{ planObj.tagline3 }}</h2>
            </div>

            <div class="mt-2 p-2" style="border: 1px solid red; border-radius: 10px; background-color: rgb(238, 238, 238)" *ngIf="isShowLimitedTime()">
              <div style="text-align: center;"><div style="display: inline-block; margin: auto; font-size: 150%; border-bottom: 2px solid red"><h2>- 限時優惠 -</h2></div></div>
              <div style="text-align: center; color: red;" class="mt-2">{{ dateFormat(planObj.limited_time_start) }} - {{ dateFormat(planObj.limited_time_end) }}</div>
              <quill-view class="qlview" [content]="planObj.limited_time_content_json" format="json" theme="snow"></quill-view>
            </div>

            <div class="mt-2 p-2" style="border: 1px solid red; border-radius: 10px; background-color: rgb(238, 238, 238)" *ngIf="planObj.show_promote">
              <div style="text-align: center;"><div style="display: inline-block; margin: auto; font-size: 150%; border-bottom: 2px solid red"><h2>- 熱門推介 -</h2></div></div>
              <quill-view class="qlview" [content]="planObj.promote_content_json" format="json" theme="snow"></quill-view>
            </div>

            <div class="mt-2 p-2" style="border: 1px solid blue; border-radius: 10px; background-color: rgb(238, 238, 238)">
              <div style="text-align: center;"><div style="display: inline-block; margin: auto; font-size: 150%; border-bottom: 2px solid blue"><h2>計劃詳情</h2></div></div>
              <quill-view class="qlview" [content]="planObj.content_json" format="json" theme="snow"></quill-view>
            </div>

            <div class="mt-2 p-2 mb-2" style="border: 1px solid blue; border-radius: 10px; background-color: rgb(238, 238, 238)">
              <div style="text-align: center;"><div style="display: inline-block; margin: auto; font-size: 150%; border-bottom: 2px solid blue"><h2>備注</h2></div></div>
              <quill-view class="qlview" [content]="planObj.remarks_json" format="json" theme="snow"></quill-view>
            </div>
          </div>
        </div>

        <div class="bottom" style="position: absolute; width: 100%; background-color: lightgrey; padding-top: 1rem; border-top: 1px solid blue;" [style]="{'bottom': bottomBarBottom}">
          <div class="row ml-2 mr-2 mb-4" style="text-align: center">
            <div class="col">
              <div style="border-radius: 100px; background-color: white; display: inline-block; width: 3rem" class="p-2" (click)="onWhatsapp()">
                <img src="/assets/img/whatsapp.png" />
              </div>
              <div style="color: blue">Whatsapp</div>
            </div>
            <div class="col">
              <div style="border-radius: 100px; background-color: white; display: inline-block; width: 3rem" class="p-2" (click)="onPhone()">
                <img src="/assets/img/telephone.png" />
              </div>
              <div style="color: blue">電話查詢</div>
            </div>
            <div class="col">
              <div style="border-radius: 100px; background-color: white; display: inline-block; width: 3rem" class="p-2" (click)="onTelegram()">
                <img src="/assets/img/telegram.png" />
              </div>
              <div style="color: blue">Telegram</div>
            </div>
            <div class="col">
              <div style="border-radius: 100px; background-color: white; display: inline-block; width: 3rem" class="p-2" (click)="onShare()">
                <img src="/assets/img/share.png" />
              </div>
              <div style="color: blue">分享</div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid empty-content" style="height: calc(100vh - 1rem - 4rem); overflow-y: scroll; padding: 0; text-align: center" *ngIf="planId == null">
        <img src="/assets/img/ic_launcher-web.png" style="width: 250px;" />

        <div style="margin-bottom: 1rem;">
          <a href="https://apps.apple.com/hk/app/%E9%9B%BB%E8%A8%8A%E5%B7%B4%E6%89%93/id1631767068"><img class="m-2" src="/assets/img/appstore.webp" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.kinandfung.telebro"><img class="m-2" src="/assets/img/googleplay.webp" /></a>
        </div>

        <div [innerHTML]="homepageContent" *ngIf="!isLoaded" class="page-content"></div>
        <!-- <quill-view class="qlview" style="overflow-y: visible;" [content]="homepageContentJson" format="json" theme="snow"></quill-view> -->

      </div>
      <h2 style="visibility: hidden;">{{ typeText }}</h2>
      <h3 style="visibility: hidden;">電訊巴打</h3>
    </section>
  </div>

  <div class="extra col" style="padding: 0px">
    <app-plan-related></app-plan-related>
  </div>
</div>


<div class="back-btn" (click)="onClose()" *ngIf="planId != null">
  <img src="/assets/img/btn-close.png" />
</div>

<ng-template #shareModal let-modal>
  <div class="modal-header">
      <span class="modal-title">分享到</span>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body">
    <share-buttons [theme]="'modern-dark'"
      [include]="['whatsapp','facebook','twitter','linkedin','telegram','messenger','line','sms','email','print','copy']"
      [title]="shareTitle"
      [description]="shareDescription"
      [autoSetMeta]="true"
    ></share-buttons>
  </div>
</ng-template>
