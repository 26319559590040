import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService, Tag } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  isDtInitialized: boolean = false;
  @ViewChild('table') table: TableComponent;
  @ViewChild('formModal') public formModal: TemplateRef<any>;
  onAdd = (): void => {
    this.onCreate(this.formModal);
  }
  dataGetClass: string = "plans";
  cols: ColumnConfig[];
  search: {key: string, value: string[]}[] = [];

  objClass: string = "plans";
  formDef: FieldConfig[];
  editObj;

  sortBy = "name";
  sortDir = "asc";
  searchType: string = "客戶名稱";
  searchText: string = "";

  modal: NgbModalRef;
  active = 1;
  public isLoading = false;

  // get selectTags(): {
  //   key: string;
  //   text: string;
  // }[] {
  //   return this.appService.getTagsByPlanType().map<{
  //     key: string;
  //     text: string;
  //   }>(t => { return {
  //     key: t.name,
  //     text: t.name,
  //   }; });
  // }

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.cols = [
      { setting: { name: 'boost', title: '置頂', render: function(data) {
        return data == "1" ? '<i class="fas fa-check"></i>' : '<i class="fas fa-times"></i>';
      } }, searchable: true, },
      { setting: { name: 'title', title: '標題' }, searchable: true, },
      { setting: { name: 'type', title: '類別', render: function(data) {
        switch(data) {
          case "fixedwired":
            return "固網寛頻";
          case "fixedcellular":
            return "家居5G寛頻";
          case "mobile":
            return "手機數據";
          case "tv":
            return "收費電視";
        }
        return "";
      } }, searchable: true, },
      { setting: { name: 'thumbnail', title: '主圖片', render: function(data) {
        return data ? '<img style="width: 100px;" src="' + data + '" />' : '';
      } }, searchable: true, },
      { setting: { name: 'tags', title: '標簽', orderable: false, searchable: true, }, },
      { setting: { name: 'status', title: '狀態', render: function(data) {
          switch(data) {
            case "normal":
              return "正常";
            case "disable":
              return "停用";
          }
          return "正常";
        } }, searchable: true, },
      { setting: { name: 'show_limited_time', title: '允許顯示限時優惠', render: function(data) {
        return data == "1" ? '<i class="fas fa-check"></i>' : '<i class="fas fa-times"></i>';
      } }, },
      { setting: { name: 'show_promote', title: '允許顯示熱門推介', render: function(data) {
        return data == "1" ? '<i class="fas fa-check"></i>' : '<i class="fas fa-times"></i>';
      } }, },
      { setting: { title: '動作', orderable: false }, buttons: [
        {
          name: "修改",
          class: "btn-primary pull-right",
          onClick: (dataItem) => this.onEditAccount(this.formModal, dataItem)
        }
      ] },
    ];

    this.search = [];

    this.formDef = [
      {
        name: "boost",
        label: "置頂",
        type: "checkbox"
      },
      {
        name: "title",
        label: "標題",
        type: "text"
      },
      {
        name: "thumbnail",
        label: "主圖片",
        type: "image",
      },
      {
        name: "background",
        label: "背景圖片",
        type: "image",
      },
      {
        name: "status",
        label: "狀態",
        type: "select",
        select: [
          {key: "normal", text: "正常"},
          {key: "disable", text: "停用"},
        ],
        valueBuilder: (objRef) => {
          return objRef.status ?? "normal";
        }
      },
      {
        name: "TeleBro_providers_id",
        label: "供應商",
        type: "select",
        selectBuilder: (objRef) => {
          if(objRef) {
            return this.appService.getProvidersByPlanType(objRef.type).map<{
              key: string;
              text: string;
            }>(t => { return {
              key: t.id.toString(),
              text: t.name,
            }; });
          }
        },
      },
      {
        name: "tags",
        label: "標簽",
        type: "multiselect",
        selectBuilder: (objRef) => {
          if(objRef) {
            return this.appService.getTagsByPlanType(objRef.type).map<{
              key: string;
              text: string;
            }>(t => { return {
              key: t.name,
              text: t.name,
            }; });
          }
        },
        maxSelect: 5
      },
      {
        name: "tagline1",
        label: "簡介一",
        type: "text"
      },
      {
        name: "tagline2",
        label: "簡介二",
        type: "text"
      },
      {
        name: "tagline3",
        label: "簡介三",
        type: "text"
      },
      {
        name: "content_json",
        label: "服務詳情",
        type: "richtext",
        onSave: (objRef, input) => {
          if(input) {
            var converter = new QuillDeltaToHtmlConverter(JSON.parse(input), {});
            var converted = converter.convert();
            objRef.content = converted;
          }
        }
      },
      {
        name: "remarks_json",
        label: "備註",
        type: "richtext",
        onSave: (objRef, input) => {
          if(input) {
            var converter = new QuillDeltaToHtmlConverter(JSON.parse(input), {});
            objRef.remarks = converter.convert();
          }
        }
      },
      {
        name: "show_limited_time",
        label: "允許顯示限時優惠",
        type: "checkbox"
      },
      {
        name: "limited_time_content_json",
        label: "限時優惠內容",
        type: "richtext",
        onSave: (objRef, input) => {
          if(input) {
            var converter = new QuillDeltaToHtmlConverter(JSON.parse(input), {});
            objRef.limited_time_content = converter.convert();
          }
        }
      },
      {
        name: "show_promote",
        label: "允許顯示熱門推介",
        type: "checkbox"
      },
      {
        name: "promote_content_json",
        label: "熱門推介內容",
        type: "richtext",
        onSave: (objRef, input) => {
          if(input) {
            var converter = new QuillDeltaToHtmlConverter(JSON.parse(input), {});
            objRef.promote_content = converter.convert();
          }
        }
      },
      {
        name: "type",
        type: "hidden",
        value: "customer"
      },
    ];
  }

  onCreate(formModal) {
    this.editObj = undefined;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onEditAccount(formModal, dataItem) {
    this.editObj = dataItem;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onSubmitted(result) {
    this.table.onTableRefresh();
    this.modal.dismiss('Saved');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
