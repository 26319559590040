import {
    NAVBAR_DARK_VARIANTS,
    NAVBAR_LIGHT_VARIANTS,
    SIDEBAR_DARK_SKINS,
    SIDEBAR_LIGHT_SKINS
} from '@/utils/themes';
import * as Actions from './actions';
import {StateAction} from './actions';
import initialState, {StateState} from './state';

export function stateReducer(state: StateState = initialState, action: StateAction) {
    switch (action.type) {
        case Actions.UPDATE_SELECTED_NEWS_ID:
            return {
                ...state,
                newsId: action.payload
            };
        case Actions.UPDATE_SELECTED_PLAN_ID:
            return {
                ...state,
                planId: action.payload
            };
        case Actions.UPDATE_CONFIG:
          return {
            ...state,
            config: action.payload
          };
        case Actions.UPDATE_BANNERS:
          return {
            ...state,
            banners: action.payload
          };
        case Actions.UPDATE_PROVIDERS:
          return {
            ...state,
            providers: action.payload
          };
        case Actions.UPDATE_ADS:
          return {
            ...state,
            ads: action.payload
          };
        case Actions.SET_UI_MODE:
          return {
            ...state,
            uiMode: action.payload
          }
        case Actions.CAHNGE_PAGE:
          if(state.pageType === action.payload) {
            return state;
          }
          return {
            ...state,
            pageType: action.payload,
            searchWord: null,
            providerId: null
          }
        case Actions.SET_SEARCH:
          return {
            ...state,
            searchWord: action.payload
          }
        case Actions.SET_PROVIDER:
          return {
            ...state,
            providerId: action.payload
          }
        default:
            return state;
    }
}
