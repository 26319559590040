import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-datefield',
    templateUrl: './datefield.component.html',
    styleUrls: ['./datefield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormDateFieldComponent extends Field {
    config: FieldConfig;
    group: UntypedFormGroup;

    @ViewChild("datebox") datebox: ElementRef;

    valueChangeSubscribe: Subscription;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // constructor(){}
    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);
    }

    ngAfterViewInit() {
        if(this.group.value[this.config.name]) {
            this.datebox.nativeElement.value = this.group.value[this.config.name];
        }
        if(this.config.onValueChanges) {
          this.valueChangeSubscribe = this.form.form.controls[this.config.name].valueChanges.subscribe((v) => this.config.onValueChanges(v, this.form, this));
          this.config.onValueChanges(this.group.value[this.config.name], this.form, this);
        }

        // var checkboxarray = this.datebox;
        // var currentValues: string[] = this.group.value[this.config.name].split(',');
        // currentValues.forEach(v => checkboxarray.filter(c => c.nativeElement.value == v).forEach(c => c.nativeElement.checked = true));
    }

    onChange($event) {
        this.group.controls[this.config.name].setValue($event.year + '-' + $event.month.toString().padStart(2, '0') + '-' + $event.day.toString().padStart(2, '0'));
    }

    onCurrentObjChange = async (currentObj) => {
      if(this.form.form.controls[this.config.name].value && this.datebox) {
        this.datebox.nativeElement.value = this.form.form.controls[this.config.name].value;
        // this.config.onValueChanges(this.form.form.controls[this.config.name].value, this.form, this);
      }
    }

    reset() {
        this.datebox.nativeElement.value = "";
    }

    minDate() : NgbDateStruct {
      if(this.config.options && this.config.options.indexOf('dob') >= 0) {
        return {year: dayjs().year() - 120, month: 1, day: 1 };
      }

      return {year: dayjs().year() - 10, month: 1, day: 1 };
    }

    maxDate() : NgbDateStruct {
      if(this.config.options && this.config.options.indexOf('dob') >= 0) {
        return {year: dayjs().year(), month: dayjs().month(), day: dayjs().day() };
      }

      return {year: dayjs().year() + 10, month: 12, day: 31 };
    }

    ngOnDestroy() {
      if(this.valueChangeSubscribe) { this.valueChangeSubscribe.unsubscribe(); }
    }
}
