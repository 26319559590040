import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;

    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
    }
}

export const MENU = [
    {
        name: '首頁',
        icon: 'fa-tachometer-alt',
        path: ['/dashboard']
    },
    {
        name: '會員列表',
        icon: 'fa-address-book',
        path: ['/member']
    },
    {
        name: '銷售員列表',
        icon: 'fa-address-book',
        path: ['/sales']
    },
    {
        name: '計劃列表',
        icon: 'fa-address-book',
        path: ['/plan']
    },
    {
        name: '橫額列表',
        icon: 'fa-address-book',
        path: ['/banner']
    },
    {
        name: '廣告列表',
        icon: 'fa-address-book',
        path: ['/ad']
    },
    {
        name: '新聞列表',
        icon: 'fa-address-book',
        path: ['/news']
    },
    {
        name: '計劃標簽列表',
        icon: 'fa-address-book',
        path: ['/tag']
    },
    {
        name: '服務供應商列表',
        icon: 'fa-address-book',
        path: ['/company']
    },
    {
        name: '設定',
        icon: 'fa-address-book',
        path: ['/config']
    }
];
