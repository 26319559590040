import {Component, ElementRef, Input, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-multiselectfield',
    templateUrl: './multiselectfield.component.html',
    styleUrls: ['./multiselectfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormMultiSelectFieldComponent extends Field {
    config: FieldConfig;
    group: UntypedFormGroup;

    @ViewChildren("checkboxs") checkboxs: QueryList<ElementRef>;

    constructor(private toastr: ToastrService) { super(); }

    ngOnInit() {
        this.form.addField(this);
    }

    ngAfterViewInit() {
        var checkboxarray = this.checkboxs.toArray();
        var currentValues: string[] = [];
        if(this.group.value[this.config.name]) { currentValues = this.group.value[this.config.name].split(','); }
        currentValues.forEach(v => checkboxarray.filter(c => c.nativeElement.value == v).forEach(c => c.nativeElement.checked = true));
    }

    getConfig(selectoption: {
        key: string;
        text: string;
    }): FieldConfig {
        var f: FieldConfig = {
            name: this.config.name,
            label: selectoption.text,
            type: 'select'
        };
        return f;
    }

    onChange($event, selectoption: { key: string; text: string; }) {
        var currentValues: string[] = [];
        try { currentValues = this.group.value[this.config.name].split(','); } catch(e) {}

        if($event.target.checked) {
            if(currentValues.indexOf(selectoption.key) < 0) {
                if(this.config.maxSelect != null && currentValues.length >= this.config.maxSelect) {
                    this.toastr.error('此項目最多只可選 ' + this.config.maxSelect + ' 項');
                    $event.target.checked = false;
                    return false;
                }
                currentValues.push(selectoption.key);
            }
        } else {
            if(currentValues.indexOf(selectoption.key) > -1) {
                currentValues.splice(currentValues.indexOf(selectoption.key), 1);
            }
        }
        var updateData: {} = {};
        updateData[this.config.name] = currentValues.join(',');
        this.group.patchValue(updateData);
    }
}
