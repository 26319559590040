<!-- Main content -->
<section class="content" style="padding: 0;">
  <div class="col container-fluid" style="padding: 0;">
    <h4 class="m-2">相關資訊</h4>
    <div *ngFor="let news of relatedNews" class="m-1 mb-2" (click)="onClick(news)" style="cursor: pointer">
      <img src="{{ news.banner }}" style="width: 100%" />
      <div style="color: grey">#{{ news.tags }}</div>
      {{ news.title }}
    </div>
  </div>
</section>
