import { ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { ChangePage, SetProvider, SetSearch, SetUiMode, UpdateSelectedNewsId, UpdateSelectedPlanId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { isThisTypeNode } from 'typescript';
import { Location } from '@angular/common';
import { AppState } from '@/store/state';

@Component({
  selector: 'app-star-plan-list',
  templateUrl: './star-plan-list.component.html',
  styleUrls: ['./star-plan-list.component.scss']
})
export class StarPlanListComponent implements OnInit {
  throttle = 0;
  distance = 2;
  page = 1;
  news = [];
  ads = null;
  banners = null;
  providers = null;
  salesicon = null;

  searchword: string;
  currentSearchWord: string;
  selectedProviderId: number;

  starredPlans: any[] = [];

  type = "";

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private router: Router,
      private location: Location,
      private route: ActivatedRoute,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type');
    // console.log(this.type);
    // this.store.select('state').subscribe((s) => {
    //   if(s.pageType !== this.type || this.currentSearchWord !== s.searchWord || this.selectedProviderId !== s.providerId) {
    //     this.type = s.pageType;
    //     this.searchword = this.currentSearchWord = s.searchWord;
    //     this.selectedProviderId = s.providerId;
    //     console.log(this.type);
    //     this.getPlans(false);
    //   }

    //   if(s.banners) {
    //     this.banners = s.banners.filter(b => b.type == this.type);
    //     console.log(this.banners);
    //   }

    //   if(s.providers) {
    //     this.providers = s.providers.filter(b => b.category == this.type);
    //     console.log(this.providers);
    //   }

    //   if(s.ads) {
    //     this.ads = s.ads.filter(b => b.type == this.type);
    //     console.log(this.ads);
    //   }

    //   if(s.config) {
    //     this.salesicon = s.config.salesicon;
    //   }
    // });
  }

  bannerClick(banner) {
    if(banner.link) {
      window.open(banner.link);
    }
  }

  async ngAfterViewInit() {
    if(this.appService.user) {
      this.starredPlans = (await this.appService.dataGet('user_stars', {key: 'TeleBro_users_id', value: this.appService.user.id})).payload.results;
    }
    if(this.type) {
      this.getPlans(false);
    }
  }

  ngOnDestroy(): void {
  }

  async onScroll(): Promise<void> {
    this.page++;
    this.getPlans(true);
  }

  changeType(type) {
    this.type = type;
    this.getPlans(false);
    this.location.replaceState('/member/stars/' + this.type + '/');
    this.store.dispatch(new UpdateSelectedPlanId(null));
    this.store.dispatch(new SetUiMode('listview'));
  }

  onNewsItemSelect(newsItem) {
    this.store.dispatch(new UpdateSelectedPlanId(newsItem.id));
    this.location.replaceState('/member/stars/' + this.type + '/' + newsItem.id);
    this.store.dispatch(new SetUiMode('contentview'));
    // this.router.navigateByUrl('/plans/' + this.type + '/' + newsItem.id);
  }

  onViewChangeToNews() {
    this.router.navigateByUrl('/news');
  }

  onViewChange(type) {
    this.router.navigateByUrl('/plans/' + type);
    // this.type = type;
    this.throttle = 0;
    this.distance = 2;
    this.page = 1;
    this.news = [];
    this.appService.dataGet('plans', null, [{
      key: 'type',
      value: [this.type]
    }], null, null, 'boost,rand()', 10, (this.page - 1) * 10).then((result) => {
      this.news = result.payload.results;
    });
  }

  onSearch() {
    // console.log(this.searchword);
    if(this.searchword && this.searchword.length < 2) {
      alert('請輸入兩個字或以上用作搜尋');
      return;
    }

    this.store.dispatch(new SetSearch(this.searchword));
  }

  onProviderSelect(provider) {
    this.store.dispatch(new SetProvider(!provider ? null : provider.id));
  }

  getPlans(more: boolean = false) {
    this.news = this.starredPlans.map(s => s.related.plans[0]).filter(p => p.type == this.type);
  }

  onStarClick(plan) {
    if(!this.appService.user) {
      this.router.navigate(['login']);
      return;
    }

    var starred = this.isPlanStar(plan);
    if(starred) {
      this.appService.dataDelete('user_stars', [starred.id]).then((rtn) => {
        this.starredPlans.splice(this.starredPlans.findIndex(s => s.TeleBro_plans_id == plan.id), 1);
        this.toastr.success('已從收藏計劃中移除');
        this.getPlans();
      });
    } else {
      this.appService.dataPost('user_stars', [{
        TeleBro_users_id: this.appService.user.id,
        TeleBro_plans_id: plan.id
      }]).then((rtn) => {
        this.starredPlans.push({...rtn.payload.entry, id: rtn.payload.entry_id});
        this.toastr.success('已加到你的收藏計劃內');
        // this.cdr.detectChanges();
      });
    }
  }

  isPlanStar(plan) {
    return this.starredPlans.find(s => s.TeleBro_plans_id == plan.id);
  }
}
