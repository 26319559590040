import {Component, Input} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@components/form/form.component';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-selectfield',
    templateUrl: './selectfield.component.html',
    styleUrls: ['./selectfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormSelectFieldComponent extends Field {
    form: FormComponent;
    config: FieldConfig;
    group: UntypedFormGroup;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);

        if(this.config.selectBuilder != null) {
            this.config.select = this.config.selectBuilder(this.editObj);
        }
        if(this.config.valueBuilder != null) {
            this.config.value = this.config.valueBuilder(this.editObj);
        }
    }

    ngAfterViewInit() {
        this.group.get(this.config.name).setValue(this.config.value);
    }

    onCurrentObjChange = async (currentObj) => {

      if(this.config.selectBuilder != null) {
        var selects = this.config.selectBuilder(currentObj);
        if(!this.isSelectsEqual(selects, this.config.select)) {
          this.config.select = this.config.selectBuilder(currentObj);
        }
      }
    }

    private isSelectsEqual(a: {key: string;text: string;}[], b: {key: string;text: string;}[]) {
      if(!a || !b) { return false; }
      if(a.length != b.length) {
        return false;
      }
      for(var i=0;i<a.length;i++) {
        if(b.find(bs => bs.key == a[i].key && bs.text == a[i].text) == undefined) {
          return false;
        }
      }
      return true;
    }
}
