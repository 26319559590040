import {Component, Input} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-colorfield',
    templateUrl: './colorfield.component.html',
    styleUrls: ['./colorfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormColorFieldComponent extends Field {
    config: FieldConfig;
    group: UntypedFormGroup;
    color: string;

    ngOnInit() {
        this.form.addField(this);
    }

    ngAfterViewInit() {
        this.color = this.group.value[this.config.name];
    }

    onColorChange($event) {
        this.color = $event;
        
        var updateData: {} = {};
        updateData[this.config.name] = this.color;
        this.group.patchValue(updateData);
    }

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // constructor(){}
    // config: FieldConfig;
}