import {Component, EventEmitter, Input, Output} from '@angular/core';
import { AppService } from '@services/app.service';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {

    selectedFile: ImageSnippet;
    @Input() imageUrl: string;
    @Output() onUpdateCallback: EventEmitter<string> = new EventEmitter<string>();

    constructor(private appService: AppService){}

    processFile(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();
    
        reader.addEventListener('load', async (event: any) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);
            this.imageUrl = await this.appService.uploadFile(this.selectedFile.file);
            this.onUpdateCallback.emit(this.imageUrl);
        });
    
        reader.readAsDataURL(file);
    }

    removeImage() {
        this.selectedFile = null;
        this.imageUrl = null;
        this.onUpdateCallback.emit(null);
    }
}

class ImageSnippet {
    constructor(public src: string, public file: File) {}
}