import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';
import { FieldConfig } from '@components/formfields/field-config.interface';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  isDtInitialized: boolean = false;
  @ViewChild('table') table: TableComponent;
  @ViewChild('formModal') public formModal: TemplateRef<any>;
  onAdd = (): void => {
    this.onCreate(this.formModal);
  }
  dataGetClass: string = "tags";
  cols: ColumnConfig[];
  search: {key: string, value: string[]}[] = [];

  objClass: string = "tags";
  formDef: FieldConfig[];
  editObj;

  sortBy = "name";
  sortDir = "asc";
  searchType: string = "客戶名稱";
  searchText: string = "";

  modal: NgbModalRef;
  active = 1;
  public isLoading = false;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.cols = [
      { setting: { name: 'type', title: '類別', render: function(data) {
        switch(data) {
          case "fixedwired":
            return "固網寛頻";
          case "fixedcellular":
            return "家居5G寛頻";
          case "mobile":
            return "手機數據";
          case "tv":
            return "收費電視";
        }
        return "";
      } }, searchable: true, },
      { setting: { name: 'name', title: '名稱' }, searchable: true, },
      { setting: { title: '動作', orderable: false }, buttons: [
        {
          name: "修改",
          class: "btn-primary pull-right",
          onClick: (dataItem) => this.onEditAccount(this.formModal, dataItem)
        }
      ] },
    ];

    this.search = [];

    this.formDef = [
      {
        name: "type",
        label: "類別",
        type: "select",
        select: [
          {key: "fixedwired", text: "固網寛頻"},
          {key: "fixedcellular", text: "家居5G寛頻"},
          {key: "mobile", text: "手機數據"},
          {key: "tv", text: "收費電視"},
        ]
      },
      {
        name: "name",
        label: "名稱",
        type: "text"
      }
    ];
  }

  onCreate(formModal) {
    this.editObj = undefined;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onEditAccount(formModal, dataItem) {
    this.editObj = dataItem;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onSubmitted(result) {
    this.table.onTableRefresh();
    this.modal.dismiss('Saved');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
