import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {Component, Inject, Injector, PLATFORM_ID, ViewContainerRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { AppState } from './store/state';
import { ChangePage, SetUiMode } from './store/state/actions';
import { StateState } from './store/state/state';
import { OneSignal } from 'onesignal-ngx';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
  viewcount: number;
  pageType: string;
  uiMode: string;
  planId;

  viewcountuptime;

  constructor(
    private toastr: ToastrService,
    public appService: AppService,
    private modalService: NgbModal,
    public viewContainerRef: ViewContainerRef,
    private injector: Injector,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private oneSignal: OneSignal,
    private metaTagService: Meta,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    if(this.isBrowserOnly) {
      this.store.select('state').subscribe(n => {
        this.pageType = n.pageType;
        this.uiMode = n.uiMode;
        this.planId = n.planId;
        if(n.config) {
          this.viewcount = n.config.viewcount;
          if(this.viewcountuptime == null || ((new Date()).getTime() - this.viewcountuptime.getTime()) / 1000 > 5) {
            this.viewcountuptime = new Date();
            this.appService.dataPostByUrl('custapi/v1/service/TeleBro/User/AddViewCount', []);
          }
        }
      });
      this.oneSignal.init({
        appId: "19971c99-e62c-4ebb-88af-6cfe5d101924",
      }).then(() => {});
    }
    this.metaTagService.updateTag({name: 'og:type', content: 'article'});
    this.metaTagService.updateTag({name: 'og:title', content: '電訊優惠比較平台-家居寛頻、商業寛頻、流動通訊、收費電視 - 電訊巴打'});
    // this.metaTagService.updateTag({name: 'og:description', content: '電訊巴打致力向香港市民尋找電訊優惠。服務範圍包括：家居寛頻及電話、商業網絡、流動通訊、收費電視。使用電訊巴打用戶就能快捷比較不同供應商的服務計劃尋找最合適自己的計劃以及足不出戶輕鬆辦理。'});
    // this.metaTagService.updateTag({name: 'description', content: '電訊巴打致力向香港市民尋找電訊優惠。服務範圍包括：家居寛頻及電話、商業網絡、流動通訊、收費電視。使用電訊巴打用戶就能快捷比較不同供應商的服務計劃尋找最合適自己的計劃以及足不出戶輕鬆辦理。'});
    this.updateCanonical();
    this.updateAlternateLang();
  }

  onViewChangeToNews($event) {
    $event.preventDefault();
    this.router.navigateByUrl('/');
    this.store.dispatch(new ChangePage('news'));
    this.store.dispatch(new SetUiMode('listview'));
  }

  onViewChange($event, type) {
    $event.preventDefault();
    this.router.navigateByUrl('/plans/' + type);
    this.store.dispatch(new ChangePage(type));
    this.store.dispatch(new SetUiMode('listview'));
  }

  onMemberClick() {
    this.router.navigate(['login']);
  }

  get isBrowserOnly(): boolean {
     return isPlatformBrowser(this.platformId);
  }

  updateCanonical() {
    let canonicalUrl = "https:" + this.document.location.href.replace('http:', '');
    if(canonicalUrl == "https://www.telecombrother.com/plans/mobile/227") {
      canonicalUrl = "https://www.telecombrother.com/plans/mobile";
    }
    const head = this.document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', canonicalUrl);
  }

  updateAlternateLang() {
    const canonicalUrl = "https:" + this.document.location.href.replace('http:', '');
    const head = this.document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'alternate');
    element.setAttribute('hreflang', 'zh-hk');
    element.setAttribute('href', canonicalUrl);
  }
}
