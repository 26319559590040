import {Component, Input} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-labelfield',
    templateUrl: './labelfield.component.html',
    styleUrls: ['./labelfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormLabelFieldComponent extends Field {
    currentObj: object;
    editObj: object;
    config: FieldConfig;
    group: UntypedFormGroup;

    value;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // constructor(){}
    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);
        this.value = this.config.value;
    }

    onCurrentObjChange = async (currentObj) => {
        if(this.config.valueBuilder) {
          this.value = this.config.valueBuilder(currentObj);
        }
        if(this.form.form.controls[this.config.name].value != this.value) {
            this.form.form.controls[this.config.name].setValue(this.value);
        }
    }
}
