<div class="form-group row" [formGroup]="group">
    <label class="col-sm-2 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <div class="col-sm-10">
        <div class="input-group">
          <app-date-time-picker [formControlName]="config.name"></app-date-time-picker>
        </div>
        <label class="remark" *ngIf="config.remark">{{ config.remark }}</label>
    </div>
    <input type="hidden" [formControlName]="config.name" />
</div>
