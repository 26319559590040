<div class="form-group row" [formGroup]="group">
    <label class="col-sm-12 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <input type="hidden" [formControlName]="config.name" #hidden />
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs w-100">
        <li *ngFor="let taboption of config.tab; index as i; " [ngbNavItem]="taboption.key">
          <a ngbNavLink>{{ taboption.text }}</a>
          <ng-template ngbNavContent>
            <app-form [config]="taboption.config" [editObj]="editObj" [onSubmit]="onSubmit" (submit)="onSubmitted($event)" #form></app-form>
          </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="pt-2 pl-2 pr-2 mb-2 border-right border-left border-bottom w-100"></div>
</div>