<div class="form-group row" *ngIf="config.isShow == undefined || config.isShow(form.currentObj)">
    <label class="col-sm-2 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <div class="col-sm-10 pills">
        <div *ngFor="let selectoption of config.select" (click)="onSelect(selectoption.key)" [ngClass]="[(isSelected(selectoption.key) ? 'selected' : ''), (config.disabled ? 'disabled' : '')]">
          {{selectoption.text}}
        </div>
        <input class="form-control" *ngIf="isAllowOther()" [ngClass]="isSelected('other') ? 'selected' : ''" placeholder="輸入其他..." [(ngModel)]="othervalue" (input)="onOtherChange($event)"  [disabled]="config.disabled" />
    </div>
</div>
<ng-template [formGroup]="group">
  <input type="hidden" [formControlName]="config.name" />
</ng-template>
