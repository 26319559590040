import {Component, Input, OnChanges} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-multifilefield',
    templateUrl: './multifilefield.component.html',
    styleUrls: ['./multifilefield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormMultiFileFieldComponent extends Field {
    config: FieldConfig;
    group: UntypedFormGroup;

    files: string[] = [];
    selectedFile: ImageSnippet;
    imageUrl: string;
    uploadingCount: number = 0;
    get uploadingCountArray() { return new Array(this.uploadingCount); }

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    constructor(private appService: AppService){
      super();
    }
    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);
    }

    async ngAfterViewInit() {
      if(this.form.form.controls[this.config.name].value) {
        this.files = this.form.form.controls[this.config.name].value;
      }
    }

    imageview(url: string) {
      window.open(url, '_blank').focus();
    }

    imageremove = (url: string) => {
      if(confirm('確定要從' + this.config.label + '中移除檔案嗎？')) {
        this.files = this.files.filter(f => f != url);
        this.form.form.controls[this.config.name].setValue(this.files);
        // console.log(this.files.slice(this.files.indexOf(url), this.files.indexOf(url) + 1));
      }
    }

    onCurrentObjChange = async (currentObj) => {
      if(currentObj[this.config.name]) {
        this.files = currentObj[this.config.name];
      }
    }

    processFile(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', async (event: any) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);
            if(this.selectedFile.file.size > 40 * 1024 * 1024) {
              alert("檔案太大，上限是 40 MB，請再嘗試。");
              return;
            }
            this.uploadingCount++;
            try {
              this.imageUrl = await this.appService.uploadFile(this.selectedFile.file);
              this.files = [...this.files, this.imageUrl];
              this.form.form.controls[this.config.name].setValue(this.files);
            } catch(e) { } finally {
              this.uploadingCount--;
            }
        });

        reader.readAsDataURL(file);
    }
}


class ImageSnippet {
  constructor(public src: string, public file: File) {}
}
