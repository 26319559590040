import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@components/form/form.component';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-tabfield',
    templateUrl: './tabfield.component.html',
    styleUrls: ['./tabfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormTabFieldComponent extends Field {
    form: FormComponent;
    editObj: object;
    config: FieldConfig;
    group: UntypedFormGroup;
    active: string;

    @ViewChild("form") subform: FormComponent;
    @ViewChild("hidden") hidden: ElementRef;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // config: FieldConfig;

    ngOnInit() {
        this.active = this.group.value[this.config.name];
        this.form.addField(this);

        if(this.config.selectBuilder != null) {
            this.config.select = this.config.selectBuilder(this.currentObj);
        }
        if(this.config.valueBuilder != null) {
            this.config.value = this.config.valueBuilder(this.editObj);
        }

        // this.group.addValidators((c) => {
        //     console.log(c);
        //     this.config.tab.find(t => t.key === this.active).config.fields.forEach(f => {
        //         var parentControl = this.group.controls[f.name];
        //         if(parentControl) {
        //             parentControl.setValue(this.form.controls[f.name]);
        //         }
        //     });
        //     console.log(this.group.controls['name']);
        //     return null;
        // });
    }

    onSubmitForm = (parentFormGroup) => {
        this.config.tab.find(t => t.key === this.active).config.fields.forEach(fr => {
          fr.forEach(f => {
            var parentControl = parentFormGroup.controls[f.name];
            if(parentControl) {
                parentControl.setValue(this.subform.value[f.name]);
            }
          });
        });
        parentFormGroup.controls[this.config.name].setValue(this.active);
    }

    ngAfterViewInit() {
        this.group.get(this.config.name).setValue(this.config.value);
    }

    onSubmit = async (apiService, object): Promise<boolean> => { return true; }

    onSubmitted(result) {
    }

    reset() {
        this.subform.form.reset();
    }
}
