import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { AdsenseModule } from 'ng2-adsense';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {ButtonComponent} from './components/button/button.component';

import {Location, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {DropdownMenuComponent} from './components/dropdown/dropdown-menu/dropdown-menu.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {ActionReducer, ActionReducerMap, MetaReducer, StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import { NgbDropdown, NgbDropdownModule, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from './components/select/select.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { AccountComponent } from '@pages/account/account.component';
import { DataTablesModule } from 'angular-datatables';
import { MemberComponent } from '@pages/member/member.component';
import { ImageUploadComponent } from '@components/image-upload/image-upload.component';
import { TableComponent } from '@components/table/table.component';
import { FormComponent } from '@components/form/form.component';
import { FormTextFieldComponent } from '@components/formfields/textfield/textfield.component';
import { DynamicFieldDirective } from '@components/formfields/dynamic-field/dynamic-field.directive';
import { FormImageFieldComponent } from '@components/formfields/imagefield/imagefield.component';
import { FormHiddenFieldComponent } from '@components/formfields/hiddenfield/hiddenfield.component';
import { SalesComponent } from '@pages/sales/sales.component';
import { FormCheckboxFieldComponent } from '@components/formfields/checkboxfield/checkboxfield.component';
import { PlanComponent } from '@pages/plan/plan.component';
import { QuillModule } from 'ngx-quill';
import { FormRichTextFieldComponent } from '@components/formfields/richtextfield/richtextfield.component';
import { BannerComponent } from '@pages/banner/banner.component';
import { AdComponent } from '@pages/ad/ad.component';
import { NewsComponent } from '@pages/news/news.component';
import { TagComponent } from '@pages/tag/tag.component';
import { CompanyComponent } from '@pages/company/company.component';
import { FormSelectFieldComponent } from '@components/formfields/selectfield/selectfield.component';
import { FormMultiSelectFieldComponent } from '@components/formfields/multiselectfield/multiselectfield.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormColorFieldComponent } from '@components/formfields/colorfield/colorfield.component';
import { ConfigComponent } from '@pages/config/config.component';
import { FormDateTimeFieldComponent } from '@components/formfields/datetimefield/datetimefield.component';
import { DateTimePickerComponent } from '@components/date-time-picker/date-time-picker.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';
import { NewsListComponent } from '@pages/news-list/news-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NewsContentComponent } from '@pages/news-content/news-content.component';
import { stateReducer } from './store/state/reducer';
import { PlanListComponent } from '@pages/plan-list/plan-list.component';
import { PlanContentComponent } from '@pages/plan-content/plan-content.component';
import { NewsContentPageComponent } from '@pages/news-content-page/news-content-page.component';
import { ShareModule } from 'ngx-sharebuttons';
import { ContentHomeComponent } from '@pages/content-home/content-home.component';
import { PlanContentPageComponent } from '@pages/plan-content-page/plan-content-page.component';
import { AppState } from './store/state';
import { localStorageSync } from 'ngrx-store-localstorage';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { NewsRelatedComponent } from '@pages/news-related/news-related.component';
import { PlanRelatedComponent } from '@pages/plan-related/plan-related.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MemberPageComponent } from '@pages/member-page/member-page.component';
import { FacebookLoginProvider, GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { MemberPlansPageComponent } from '@pages/member-plans-page/member-plans-page.component';
import { MemberPlanPageComponent } from '@pages/member-plan-page/member-plan-page.component';
import { FormNumberFieldComponent } from '@components/formfields/numberfield/numberfield.component';
import { FormLabelFieldComponent } from '@components/formfields/labelfield/labelfield.component';
import { FormPasswordFieldComponent } from '@components/formfields/password/password.component';
import { FormTextAutoCompleteFieldComponent } from '@components/formfields/textautocompletefield/textautocompletefield.component';
import { FormButtonFieldComponent } from '@components/formfields/buttonfield/buttonfield.component';
import { FormTextAreaComponent } from '@components/formfields/textarea/textarea.component';
import { FormMultiFileFieldComponent } from '@components/formfields/multifilefield/multifilefield.component';
import { FormButtonSelectFieldComponent } from '@components/formfields/buttonselectfield/buttonselectfield.component';
import { FormDropdownFieldComponent } from '@components/formfields/dropdownfield/dropdownfield.component';
import { FormDateFieldComponent } from '@components/formfields/datefield/datefield.component';
import { FormTabFieldComponent } from '@components/formfields/tabfield/tabfield.component';
import { FormSeparatorComponent } from '@components/formfields/separator/separator.component';
import { NumpadComponent } from '@components/numpad/numpad.component';
import { StarPlansPageComponent } from '@pages/star-plans-page/star-plans-page.component';
import { StarPlanListComponent } from '@pages/star-plan-list/star-plan-list.component';
import { RegisterPageComponent } from '@pages/register-page/register-page.component';
import { MemberInfoPageComponent } from '@pages/member-info-page/member-info-page.component';
import { CalculatorPageComponent } from '@pages/calculator-page/calculator-page.component';
import { MatSliderModule } from '@angular/material/slider';
import { OnesignalNgxModule } from 'onesignal-ngx';
import { PrivacyPageComponent } from '@pages/privacy-page/privacy-page.component';
import { ForgetpasswordPageComponent } from '@pages/forgetpassword-page/forgetpassword-page.component';
import { JwtModule } from "@auth0/angular-jwt";
import { HomeComponent } from '@pages/home/home.component';
import { HomeContentComponent } from '@pages/home-content/home-content.component';

registerLocaleData(localeEn, 'en-EN');

const reducers: ActionReducerMap<AppState> = {
  ui: uiReducer,
  auth: authReducer,
  state: stateReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({
    keys: [
      'state'
    ],
    rehydrate: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,

        HomeComponent,
        HomeContentComponent,
        NewsListComponent,
        NewsContentComponent,
        NewsRelatedComponent,
        NewsContentPageComponent,
        ContentHomeComponent,
        PlanListComponent,

        PlanContentComponent,

        PlanRelatedComponent,
        PlanContentPageComponent,
        LoginPageComponent,
        ForgetpasswordPageComponent,
        RegisterPageComponent,
        MemberPageComponent,
        MemberPlansPageComponent,
        MemberPlanPageComponent,
        MemberInfoPageComponent,
        StarPlansPageComponent,
        StarPlanListComponent,
        CalculatorPageComponent,
        PrivacyPageComponent,

        AccountComponent,
        MemberComponent,
        SalesComponent,
        PlanComponent,
        BannerComponent,
        AdComponent,
        NewsComponent,
        TagComponent,
        CompanyComponent,
        ConfigComponent,

        BlankComponent,
        ProfileComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,

        ButtonComponent,
        ImageUploadComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        PrivacyPolicyComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        DropdownComponent,
        DropdownMenuComponent,
        ControlSidebarComponent,
        SelectComponent,
        CheckboxComponent,
        TableComponent,
        DateTimePickerComponent,

        DynamicFieldDirective,
        FormComponent,
        FormTextFieldComponent,
        FormTextAutoCompleteFieldComponent,
        FormButtonFieldComponent,
        FormLabelFieldComponent,
        FormNumberFieldComponent,
        FormPasswordFieldComponent,
        FormTextAreaComponent,
        FormImageFieldComponent,
        FormMultiFileFieldComponent,
        FormHiddenFieldComponent,
        FormCheckboxFieldComponent,
        FormRichTextFieldComponent,
        FormSelectFieldComponent,
        FormButtonSelectFieldComponent,
        FormMultiSelectFieldComponent,
        FormDropdownFieldComponent,
        FormDateFieldComponent,
        FormDateTimeFieldComponent,
        FormColorFieldComponent,
        FormTabFieldComponent,
        FormSeparatorComponent,

        DateTimePickerComponent,
        NumpadComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        // StoreModule.forRoot({auth: authReducer, ui: uiReducer, state: stateReducer}),
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
          logOnly: environment.production, // Restrict extension to log-only mode
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        NgbModule,
		    NgChartsModule,
        NgbDropdownModule,
        NgbModalModule,
        DataTablesModule,
        ColorPickerModule,
        QuillModule.forRoot({
            format: "json"
        }),
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        FontAwesomeModule,
        InfiniteScrollModule,
        QuillModule.forRoot(),
        ShareModule,
        ShareButtonsModule,
        FontAwesomeModule,
        ShareIconsModule,
        SocialLoginModule,
        AdsenseModule.forRoot({
          // adClient: 'ca-pub-9036054596560617',
          // adSlot: '6152616511',
          // adtest: 'on',
          adClient: 'ca-pub-9036054596560617',
          adSlot: '5474863620',
          adFormat: 'auto',
          fullWidthResponsive: true,
          adtest: 'on',
          // layoutKey: '-fb+5w+4e-db+86',
          // adClient: 'ca-pub-0000000000000000',
          // adSlot: '0000000000',
          // adtest: 'adtest'
        }),
        MatSliderModule,
        OnesignalNgxModule,
        JwtModule.forRoot({
          config: {
            tokenGetter: () => {
              return localStorage.getItem('access_token');
            },
            // allowedDomains: ['localhost:4200', 'localhost:3000', 'www.abc.com', 'abc.com'],
            // disallowedRoutes: ['http://example.com/examplebadroute/'],
          },
        }),
        GoogleSigninButtonModule
    ],
    providers: [
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '1095604504389-15v4dvkqfid6d1l81agk7j7t4av98dlp.apps.googleusercontent.com', {
                  oneTapEnabled: false,
                }
              )
            },
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider('685361479391034')
            }
          ],
          onError: (err) => {
            console.error(err);
          }
        } as SocialAuthServiceConfig,
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCalendar, faClock);
  }
}
