<div class="row main-wrapper m-0" style="height: 100%">
  <div class="main col" style="height: 100%; overflow-y: scroll;">
    <section class="content" style="padding: 0;">
      <div class="container-fluid" style="padding: 0;" *ngIf="newsId != null && newsObj != undefined">
        <img src="{{ newsObj.banner }}" style="width: 100%" />
        <div class="heading pl-2 pr-2">
          <h1>{{ newsObj.title }}</h1>
          <div style="float: right; cursor: pointer;" (click)="onShare()"><img src="assets/img/share.png" /></div>
          <div>{{ newsObj.date }}</div>
          <h2>#{{ newsObj.tags }}</h2>
        </div>
        <div>
          <quill-view [content]="newsObj.content_json" format="json" theme="snow"></quill-view>
        </div>
      </div>

      <div class="container-fluid" style="padding: 0; text-align: center" *ngIf="newsId == null">
        <img src="/assets/img/ic_launcher-web.png" style="max-width: 250px;" />

        <div style="margin-bottom: 1rem;">
          <a href="https://apps.apple.com/hk/app/%E9%9B%BB%E8%A8%8A%E5%B7%B4%E6%89%93/id1631767068"><img class="m-2" src="/assets/img/appstore.webp" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.kinandfung.telebro"><img class="m-2" src="/assets/img/googleplay.webp" /></a>
        </div>

        <h1 style="margin-bottom: 1rem;">擁抱智慧通訊，體驗電訊巴打App的五大好處</h1>


        <div class="ml-5 mr-5" style="text-align: left;">
          <p>在數位化的浪潮席捲下，電訊巴打App成為了香港人日常生活中的重要一員。這款App顛覆了傳統通訊的界限，為用戶帶來了便捷、高效和個性化的全新體驗。以下是使用電訊巴打App的五大好處，讓你的數位生活更上一層樓：
          </p>
          <h2>1.全面整合：</h2>
          <p>電訊巴打App將電話、簡訊和數據服務一網打盡，提供了一站式的解決方案。無論是帳單查詢、餘額充值，還是服務計劃的更改，透過此平台輕鬆管理，讓生活變得更加便捷無縫和高效。
          </p>
          <div class="text-center" style="margin-bottom: 1rem;"><img src="/assets/img/image-09.jpg" style="width: 540px;" /></div>

          <h2>2.個人化定制：</h2>
          <p>透過智慧推薦系統，電訊巴打App能夠根據您的使用習慣和偏好，提供客製化客製化的數據方案。介面友善、操作不一定，讓每個用戶都能享受到獨特且貼心的服務。
          </p>
          <div class="text-center" style="margin-bottom: 1rem;"><img src="/assets/img/image-10.jpg" style="width: 540px;" /></div>

          <h2>3.即時數據監控：
          </h2>
          <p>再也不用擔心數據使用超標的問題。電訊巴打App的即時監控功能能夠精準追蹤您的數據使用情況，並透過清晰的圖表和通知，讓您隨時掌握使用情況，有效規劃數據使用。
          </p>
          <!-- <div class="text-center" style="margin-bottom: 1rem;"><img src="/assets/img/image-03.jpg" style="width: 540px;" /></div> -->

          <h2>4.無縫客戶服務：
          </h2>
          <p>遇到問題煩惱。內建的客戶服務中心提供即時聊天支持，讓您輕鬆解決各種問題。無論是技術支援還是服務諮詢，電訊巴打App都可以提供快速而專業的回應。
          </p>

          <h2>5.最高安全標準：
          </h2>
          <p>我們深知安全的重要性，因此電訊巴打App採用了業界領先的加密技術來為您提供資訊安全。從個人資料到交易記錄，每一筆資料都受到嚴格保護，讓您放心使用。
          </p>

          <p>體驗電訊巴打App，開啟智慧、安全的通訊新紀元高效。立即下載，讓我們助您快速在變化的世界中，始終保持聯繫。
          </p>

          <h2>常見問題</h2>
          <h3>1. 其實上網已可查找各個電訊公司優惠、電訊報價的詳情了，為何還要多此一舉，要另下載「電訊巴打」來查看各電訊優惠呢？</h3>
          <p>今時今日的香港，通訊科技已經成為我們生活中必備的一環，在這趨勢下，電訊服務供應商的產品和服務也越見多元化。要上網尋找各電訊公司優惠、電訊報價的資料當然不難，但要自行比較哪些電訊優惠才是至抵的選擇，一下子要消化大量的數據及資料，消費者真的不易從中作出準確分析，從而有機會在電訊優惠方面作出錯誤的選擇。的確，在選擇合適的電訊公司優惠等，既花時間，更費心神，尤其是今天的香港人生活忙碌，已投入不少時間和精神在工作上，要另再花時間於電訊報價比較方面，想起也教人勞累！而為了幫助大家可更精準了解及比較不同電訊公司的優惠計劃，包括產品和服務的優惠，「電訊巴打」也因此應運而生。</p>
          <p>「電訊巴打」是一個專為用戶提供全面電訊優惠比較及資訊的一站式平台。如用戶已下載「電訊巴打」app，只要一登入，各家的電訊報價、電訊優惠詳情也一一列出，無論是固網寬頻、流動寬頻、手機數據，以及收費電視等不同種類服務的價錢和優惠等，都可經「電訊巴打」選取最適合自己的服務方案。</p>

          <h3>2. 如要直接聯絡「電訊巴打」的服務員，以了解各電訊公司優惠、電訊報價詳情，可有方法嗎？</h3>
          <p>我們也明白用戶在查詢電訊優惠時互動之重要，所以「電訊巴打」平台在用戶查詢方面提供更便捷的服務。用戶可以透過WhatsApp聯絡「電訊巴打」的服務團隊，進一步查詢和確認個人所需要的電訊優惠及服務，這樣可大大節省用戶尋找電訊公司優惠和電訊優惠資訊，以及簽約的時間和精力。總括而言，「電訊巴打」能夠讓消費者更輕鬆地去比較和選擇不同的電訊優惠，並能夠經我們專家團隊的服務，為大家提供更專業和貼心的幫助。</p>

          <h3>3. 除了電訊公司優惠、電訊報價的資料提供外，「電訊巴打」還有哪些趣味資訊也會跟用戶分享的呢？</h3>
          <p>除有關電訊優惠的資料提供外，「電訊巴打」還有其他相關網絡、電話功能等之資迅跟用戶分享，舉例說，一些最新的科技玩樂、遊戲配件及其他科技產品介紹等，大家亦可在我們的平台查看得到，完完全全掌握現今潮流趨勢。</p>

          <h3>4. 如登記成為你們「電訊巴打」的會員以查詢電訊公司優惠、電訊報價資訊等，要收取費用嗎？</h3>
          <p>當下載「電訊巴打」app後再登記成為我們的會員，在查詢電訊優惠等是費用全免的，用戶可放心作多次參閱和查詢電訊報價等。</p>

          <h3>5. 如成為你們「電訊巴打」的會員及每次登入查詢電訊優惠等資訊，除了直接填上電郵/用戶名稱和輸入密碼外，還有其他途徑可讓會員登入嗎？</h3>
          <p>如成為「電訊巴打」會員的用戶，亦可選擇以Facebook帳戶或Apple帳戶登入。</p>
        </div>
      </div>
    </section>
  </div>

  <div class="extra col" style="padding: 0px">
    <app-news-related></app-news-related>
  </div>
</div>

<div class="back-btn" (click)="onClose()" *ngIf="newsId != null">
  <img src="/assets/img/btn-close.png" />
</div>

<ng-template #shareModal let-modal>
  <div class="modal-header">
      <span class="modal-title">分享到</span>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body">
    <share-buttons [theme]="'modern-dark'"
      [include]="['whatsapp','facebook','twitter','linkedin','telegram','messenger','line','sms','email','print','copy']"
      [title]="shareTitle"
      [description]="shareDescription"
      [autoSetMeta]="true"
    ></share-buttons>
  </div>
</ng-template>
