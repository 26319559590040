import {Component, Input, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-textautocompletefield',
    templateUrl: './textautocompletefield.component.html',
    styleUrls: ['./textautocompletefield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormTextAutoCompleteFieldComponent extends Field {
    currentObj: object;
    editObj: object;
    config: FieldConfig;
    group: UntypedFormGroup;
    @ViewChild('instance', {static: true}) instance: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // constructor(){}
    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);

        if(this.config.selectBuilder != null) {
            this.config.select = this.config.selectBuilder(this.editObj);
        }
        if(this.config.valueBuilder != null) {
            this.config.value = this.config.valueBuilder(this.editObj);
        }
    }

    isConvertToGBButton(): boolean {
      return this.config.options && this.config.options.indexOf('chinese') >= 0;
    }

    isListAllOptions(): boolean {
      return this.config.options && this.config.options.indexOf('all-select') >= 0;
    }

    search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance && !this.instance.isPopupOpen()));
      const inputFocus$ = this.focus$;

      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.config.select.map(s => s.text)
          : this.config.select.filter(v => v.text.toLowerCase().indexOf(term.toLowerCase()) > -1).map(s => s.text)).slice(0, this.isListAllOptions() ? this.config.select.length : 10))
      );
    }

}
