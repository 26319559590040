import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  isDtInitialized: boolean = false;
  @ViewChild('table') table: TableComponent;
  @ViewChild('formModal') public formModal: TemplateRef<any>;
  onAdd = (): void => {
    this.onCreate(this.formModal);
  }
  dataGetClass: string = "users";
  cols: ColumnConfig[];
  search: {key: string, value: string[]}[] = [];

  objClass: string = "users";
  formDef: FieldConfig[];
  editObj;

  sortBy = "name";
  sortDir = "asc";
  searchType: string = "客戶名稱";
  searchText: string = "";

  modal: NgbModalRef;
  active = 1;
  public isLoading = false;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private socialAuthService: SocialAuthService
  ) {}

  ngOnInit(): void {
    this.cols = [
      { setting: { name: 'username', title: '用戶名' }, searchable: true, },
      { setting: { name: 'name', title: '全名' }, searchable: true, },
      { setting: { name: 'mobile', title: '電話號碼', orderable: false, searchable: true, }, },
      { setting: { name: 'email', title: '電郵' }, searchable: true, },
      { setting: { name: 'method', title: '登入方式' }, },
      { setting: { title: '動作', orderable: false }, buttons: [
        {
          name: "修改",
          class: "btn-primary pull-right",
          onClick: (dataItem) => this.onEditAccount(this.formModal, dataItem)
        }
      ] },
    ];

    this.search = [{
      key: "type",
      value: ["customer"]
    }];

    this.formDef = [
      {
        name: "thumbnail",
        label: "頭像",
        type: "image",
      },
      {
        name: "name",
        label: "全名",
        type: "text"
      },
      {
        name: "username",
        label: "用戶名",
        type: "text"
      },
      {
        name: "method",
        label: "登入方式",
        type: "text",
        value: "local",
        disabled: true
      },
      {
        name: "mobile",
        label: "電話號碼",
        type: "text"
      },
      {
        name: "email",
        label: "電郵地址",
        type: "text"
      },
      {
        name: "type",
        type: "hidden",
        value: "customer"
      },
    ];
  }

  onCreate(formModal) {
    this.editObj = undefined;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onEditAccount(formModal, dataItem) {
    this.editObj = dataItem;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onSubmitted(result) {
    this.table.onTableRefresh();
    this.modal.dismiss('Saved');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
