<div class="form-group row" [formGroup]="group" [ngClass]="config.isShow == undefined || config.isShow(form.currentObj) ? '' : 'display-none'">
    <label class="col-sm-2 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <div class="col-sm-10">
        <div class="input-group">
            <input class="form-control" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onChange($event)" #datebox [firstDayOfWeek]="7" [minDate]="minDate()" [maxDate]="maxDate()" [disabled]="config.disabled" [ngClass]="cssClass">
            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button" *ngIf="!config.disabled"><i class="fas fa-calendar"></i></button>
        </div>
        <label class="remark" *ngIf="config.remark">{{ config.remark }}</label>
    </div>
    <input type="hidden" [formControlName]="config.name" />
</div>
