import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { ChangePage, SetUiMode, UpdateSelectedNewsId, UpdateSelectedPlanId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { AppState } from '@/store/state';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { ApiService } from '@services/api.service';
import { FormConfig } from '@components/formfields/form-config.interface';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-forgetpassword-page',
  templateUrl: './forgetpassword-page.component.html',
  styleUrls: ['./forgetpassword-page.component.scss']
})
export class ForgetpasswordPageComponent implements OnInit {
  loginFormConfig: FormConfig;

  isLoading: boolean = false;
  isSubmitting: boolean = false;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      public router: Router,
      private navigation: NavigationService,
  ) {}

  ngOnInit(): void {
    this.loginFormConfig = {
      submitText: "重設密碼",
      fields: [
        [{
          name: "name",
          label: "電郵",
          type: "text"
        }],
        [{
          name: "passcode",
          type: "button",
          value: "發送驗證碼",
          disabled: this.isLoading,
          onClick: async (objRef, form, $event) => {
            if(!form.form.controls['name'].value) {
              this.toastr.error("請先輸入電郵");
              return;
            }
            this.isLoading = true;
            var result = await this.appService.requestResetPassword(form.form.controls['name'].value);
            this.isLoading = false;
          },
        }],
        [{
          name: "passcode",
          label: "驗證碼",
          type: "text"
        }],
        [{
          name: "password",
          label: "新密碼",
          type: "password"
        }],
        [{
          name: "repeatpassword",
          label: "重覆新密碼",
          type: "password"
        }],
      ]
    };
    this.store.dispatch(new ChangePage(null));
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {

  }

  onSubmit = async (apiService: ApiService, objRef) => {
    if(!objRef.name) {
      this.toastr.error("請先輸入電郵");
      return;
    }
    if(!objRef.passcode) {
      this.toastr.error("請先輸入驗證碼");
      return;
    }
    if(!objRef.password) {
      this.toastr.error("請先輸入新密碼");
      return;
    }
    if(objRef.password != objRef.repeatpassword) {
      this.toastr.error("兩次輸入的密碼不一致");
      return;
    }
    this.isSubmitting = true;
    var result = await this.appService.resetPassword(objRef.name, objRef.passcode, objRef.password);
    if(result) {
      this.router.navigate(['login']);
    }
    this.isSubmitting = false;
  }

  onFacebookLogin = async () => {

  }

  backLogin = () => {
    this.router.navigate(['login']);
  }

  onSubmitted($event) {

  }
}
