import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@components/form/form.component';
import { AppService } from '@services/app.service';
import { Subscription } from 'rxjs';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-dropdownfield',
    templateUrl: './dropdownfield.component.html',
    styleUrls: ['./dropdownfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormDropdownFieldComponent extends Field {
    form: FormComponent;
    config: FieldConfig;
    group: UntypedFormGroup;

    prevVal;

    valueChangeSubscribe: Subscription;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // config: FieldConfig;

    optionName(v) {
      var fil = this.config.select.filter((val) => val.key == v);
      return fil.length > 0 ? fil[0].text : "";
    }

    ngOnInit() {
        this.form.addField(this);

        if(this.config.selectBuilder != null) {
            this.config.select = this.config.selectBuilder(this.currentObj);
        }
        if(this.config.valueBuilder != null) {
            this.config.value = this.config.valueBuilder(this.editObj);
        }
        if(this.config.onValueChanges) {
          this.valueChangeSubscribe = this.form.form.controls[this.config.name].valueChanges.subscribe((v) => {
            if(v !== this.prevVal) {
              this.prevVal = v;
              // var fil = this.config.select.filter((val) => val.key == v);
              // this.selected = fil.length > 0 ? fil[0] : null;
              this.config.onValueChanges(v, this.form, this);
            }
          });
        }
    }

    ngAfterContentInit() {

      this.group.get(this.config.name).setValue(this.config.value);
      // if(this.config.value) {
      //     this.selected = this.config.select.find(s => s.key == this.config.value);
      // }
    }

    onCurrentObjChange = async (currentObj) => {
        if(this.config.selectBuilder != null) {
            this.config.select = this.config.selectBuilder(this.currentObj);
        }
    }

    selecting(event: Event, selectoption) {
        event.preventDefault();
        event.stopPropagation();
        // this.selected = selectoption;
        this.form.form.controls[this.config.name].setValue(selectoption.key);
    }

    // onSubmitForm = (parentFormGroup) => {
    //     parentFormGroup.controls[this.config.name].setValue(this.selected.key);
    // }

    reset() {
        // this.selected = null;
        this.form.form.controls[this.config.name].setValue(this.config.value ?? null);
    }

    ngOnDestroy() {
      if(this.valueChangeSubscribe) { this.valueChangeSubscribe.unsubscribe(); }
    }
}
