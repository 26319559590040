
<h4 style="text-align: center;">我的收藏</h4>
<div style="display: flex; justify-content: space-around;">
  <div class="p-1" style="background-color: lightgrey; border-radius: 0.25rem;" [style]="type == 'fixedwired' ? {'background-color': 'lightblue'} : {}" (click)="changeType('fixedwired')">固網寛頻</div>
  <div class="p-1" style="background-color: lightgrey; border-radius: 0.25rem;" [style]="type == 'fixedcellular' ? {'background-color': 'lightblue'} : {}" (click)="changeType('fixedcellular')">家居5G寛頻</div>
  <div class="p-1" style="background-color: lightgrey; border-radius: 0.25rem;" [style]="type == 'mobile' ? {'background-color': 'lightblue'} : {}" (click)="changeType('mobile')">手機數據</div>
  <div class="p-1" style="background-color: lightgrey; border-radius: 0.25rem;" [style]="type == 'tv' ? {'background-color': 'lightblue'} : {}" (click)="changeType('tv')">收費電視</div>
</div>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid p-0">
      <div infinite-scroll
        class="infinite-scroll"
        [scrollWindow]="false"
        [infiniteScrollDistance]="distance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onScroll()">
        <div *ngFor="let n of news; let i = index">
          <div class="news-item mt-2" style="margin: 0" (click)="onNewsItemSelect(n)" [style]="{'background-color': (n.related.providers && n.related.providers.length > 0 ? n.related.providers[0].color_code : 'white'), 'color': 'white', 'position': 'relative'}">
            <div style="border: 1px solid black; border-radius: 200px; width: 4rem; height: 4rem; position: absolute; top: 0px; right: 0px; background-image: url('{{ n.related.sales && n.related.sales.length > 0 && n.related.sales[0].thumbnail? n.related.sales[0].thumbnail : salesicon }}'); background-size: cover; background-position: center;"></div>
            <div style="position: absolute; top: 0px; right: 4.5rem; font-size: 80%; line-height: 180%;" *ngIf="n.related.sales && n.related.sales.length > 0 && n.related.sales[0].approved">銷售員已認證</div>
            <div class="pl-2">{{ n.related.providers && n.related.providers.length > 0 ?n.related.providers[0].name : '' }}</div>
            <div class="pl-2 pr-2" style="background: rgb(0,0,0); background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);">
              <div class="mb-0 pt-1 title">{{ n.title }}</div>
              <div class="tagline">- {{ n.tagline1 }}</div>
              <div class="tagline">- {{ n.tagline2 }}</div>
              <div style="float: right"><i class="fa-star" [class]="isPlanStar(n) ? 'fas' : 'far'" (click)="onStarClick(n)" style="font-size: 125%;"></i></div>
              <div class="tagline">- {{ n.tagline3 }}</div>
              <div class="row pt-2 pb-2">
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 0">{{ n.tags.split(',')[0] }}</div></div>
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 1">{{ n.tags.split(',')[1] }}</div></div>
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 2">{{ n.tags.split(',')[2] }}</div></div>
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 3">{{ n.tags.split(',')[3] }}</div></div>
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 4">{{ n.tags.split(',')[4] }}</div></div>
              </div>
            </div>
          </div>
          <div *ngIf="(i+1)%5 == 0 && ads && ads.length > 0" class="mt-2">
            <div>
              <a [attr.href]="ads[((i+1)/5)%ads.length].link" target="_blank" style="cursor: pointer">
                <img [src]="ads[((i+1)/5)%ads.length].image" style="width: 100%;" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
