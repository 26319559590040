import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Observer, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { Store } from '@ngrx/store';
import { StateState } from '@/store/state/state';
import { AppState } from '@/store/state';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateSelectedNewsId, UpdateSelectedPlanId } from '@/store/state/actions';

@Component({
  selector: 'app-plan-related',
  templateUrl: './plan-related.component.html',
  styleUrls: ['./plan-related.component.scss']
})
export class PlanRelatedComponent implements OnInit {
  private state$: Observable<any>;
  private planId: Number = null;
  planObj;
  relatedPlans: any[];

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private router: Router,
      private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    var id = this.route.snapshot.paramMap.get('id');
    // console.log(id);
    if(id != null) {
      this.planId = parseInt(id);
      this.loadRelatedPlans();
    }
    this.store.select('state').subscribe((n) => {
      if(n != null && n.planId != null) {
        this.planId = n.planId;
        this.loadRelatedPlans();
      }
    })
  }

  async ngAfterViewInit() {
    await new Promise(resolve => { setTimeout(() => { resolve(0); }, 100); });
  }

  loadRelatedPlans() {
    this.appService.dataGet("plans", {
      key: "id",
      value: this.planId.toString()
    }).then((result) => {
      if(result.payload.results.length > 0) {
        this.planObj = result.payload.results.pop();
        if(this.planObj.related && this.planObj.related.sales && this.planObj.related.sales.length > 0) {
          this.appService.dataGet("plans", null, [{
              key: 'TeleBro_sales_id',
              value: [this.planObj.related.sales[0].id]
            }],
            {
              key: 'id',
              value: this.planObj.id
            }, 'rand()'
          ).then((result2) => {
            this.relatedPlans = result2.payload.results;
          });
        }
      }
    })
  }

  loadNews() {
  }

  onClick(plan) {
    this.store.dispatch(new UpdateSelectedPlanId(plan.id));
    this.router.navigate(['plans', plan.type, plan.id]);
  }

  ngOnDestroy(): void {
  }

}
