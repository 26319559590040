<div class="form-group row" [formGroup]="group" *ngIf="config.isShow == undefined || config.isShow(form.currentObj)">
    <label class="col-sm-2 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <div class="col-sm-10">
      <div class="input-group">
        <input class="form-control"
            type="text"
            [attr.placeholder]="config.placeholder"
            [formControlName]="config.name"
            [ngbTypeahead]="search"
            (focus)="focus$.next($any($event).target.value)"
            (click)="click$.next($any($event).target.value)"
            #instance="ngbTypeahead">
      </div>
      <label class="remark" *ngIf="config.remark">{{ config.remark }}</label>
    </div>
</div>
