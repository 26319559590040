import { UntypedFormGroup } from "@angular/forms";
import { FormComponent } from "@components/form/form.component";
import { FieldConfig } from "./field-config.interface";

export class Field {
    form: FormComponent;
    currentObj: object;
    editObj: object;
    config: FieldConfig;
    group: UntypedFormGroup;

    cssClass: string;

    isRequired(): boolean {
        return this.config.validation && this.config.validation.findIndex((value, index, obj) => {
            return value.name === "required";
        }) !== -1;
    }

    onSubmitForm?: (f: UntypedFormGroup) => void;

    hasOptionFlag(t) {
        return this.config.options && this.config.options.indexOf(t) >= 0;
    }

    setCssClass(c: string) {
      this.cssClass = c;
    }

    onCurrentObjChange = async (currentObj) => {}
    reset() {}
}
