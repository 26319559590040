import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { SetUiMode, UpdateSelectedNewsId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NavigationService } from '@services/navigation.service';
import { AppState } from '@/store/state';

@Component({
  selector: 'app-star-plans-page',
  templateUrl: './star-plans-page.component.html',
  styleUrls: ['./star-plans-page.component.scss']
})
export class StarPlansPageComponent implements OnInit {

  type = "";
  viewMode = "wwwroot";

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private location: Location,
      private route: ActivatedRoute,
      private router: Router,
      private navigation: NavigationService,
  ) {}

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type');
    // console.log(this.type);

    if (this.router.url == '' || this.router.url == '/') {
      this.store.dispatch(new SetUiMode('listview'));
    } else if (this.router.url.endsWith('/')) {
      this.store.dispatch(new SetUiMode('listview'));
    } else {
      this.store.dispatch(new SetUiMode('contentview'));
    }

    this.store.select('state').subscribe((s) => {
      this.viewMode = s.uiMode;
    })
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {

  }
}
