<div class="main-wrapper">
  <h4 class="mt-2">數據用量計算機</h4>
  <p>你可以用以下計算機估計流動數據用量</p>
  <div class="row">
    <div class="col-12 col-md-6" style="display: flex;" *ngFor="let sliderObj of sliderObjs">
      <div><img style="width: 4rem; height: 4rem" class="m-1" [src]="sliderObj.icon" /></div>
      <div class="m-2" style="flex-grow: 1; text-align: left;">
        <div class="row" style="margin: 0px;">
          <div style="flex-grow: 1;">{{ sliderObj.text }}</div>
          <div>每日 {{ sliderObj.value }} {{ sliderObj.unit }}</div>
        </div>
        <div>
          <mat-slider style="width: 100%; padding: 0px" min="0" [max]="sliderObj.max" step="1">
            <input matSliderThumb [(ngModel)]="sliderObj.value">
          </mat-slider>
        </div>
      </div>
    </div>
  </div>
  <div class="row"><h4>估計用量</h4></div>
  <div class="row"><h3>每月 <span>{{ getTotalVolume() }}</span></h3></div>
  <app-button class="m-2" (click)="goToPlans()">尋找計劃</app-button>
  <app-button [color]="'disable'" (click)="reset()">重設計算機</app-button>
</div>
