<div class="main-wrapper">
  <h4 class="mt-2">登入帳戶</h4>

  <div class="mb-4 mt-4">
    <div style="display: flex; margin: auto; max-width: 350px; cursor: pointer;" class="mb-2">
      <asl-google-signin-button type='standard' size='medium'>以 Google 帳戶繼續</asl-google-signin-button>
    </div>
    <div style="display: flex; margin: auto; max-width: 350px; border: 1px solid black; border-radius: 0.5rem; cursor: pointer;" class="mb-2" (click)="onFacebookLogin()">
      <div class="pl-2 pr-2" style="border-right: 1px solid black; font-size: 150%; width: 3rem;"><i class="fab fa-facebook"></i></div>
      <div class="p-1" style="background-color: #eeeeee; flex-grow: 1; border-radius: 0px 0.5rem 0.5rem 0px; line-height: 175%">以 Facebook 帳戶繼續</div>
    </div>
    <div style="display: flex; margin: auto; max-width: 350px; border: 1px solid black; border-radius: 0.5rem; cursor: pointer;" class="mb-2" (click)="onAppleLogin()">
      <div class="pl-2 pr-2" style="border-right: 1px solid black; font-size: 150%; width: 3rem;"><i class="fab fa-apple"></i></div>
      <div class="p-1" style="background-color: #eeeeee; flex-grow: 1; border-radius: 0px 0.5rem 0.5rem 0px; line-height: 175%">以 Apple 帳戶繼續</div>
    </div>
  </div>

  <div style="display: flex" class="mb-4">
    <div style="flex-grow: 1"><hr /></div>
    <div class="ml-2 mr-2" style="line-height: 2rem; color: grey; font-size: 80%;">或使用電子郵箱 / 用戶名繼續</div>
    <div style="flex-grow: 1"><hr /></div>
  </div>
  <app-form [config]="loginFormConfig" [onSubmit]="onSubmit" (submit)="onSubmitted($event)" #form></app-form>
  <div style="color: blue; text-decoration: underline; cursor: pointer;" class="mt-2" (click)="onForgetPassword()">忘記密碼?</div>
  <div class="mt-4" style="margin-bottom: 10rem;"><app-button [color]="'warning'" (click)="onRegister()">新增帳戶</app-button></div>
</div>
