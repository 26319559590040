import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import { NewsContentPageComponent } from '@pages/news-content-page/news-content-page.component';
import { PlanContentPageComponent } from '@pages/plan-content-page/plan-content-page.component';
import { MemberPageComponent } from '@pages/member-page/member-page.component';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { MemberPlansPageComponent } from '@pages/member-plans-page/member-plans-page.component';
import { MemberPlanPageComponent } from '@pages/member-plan-page/member-plan-page.component';
import { StarPlansPageComponent } from '@pages/star-plans-page/star-plans-page.component';
import { RegisterPageComponent } from '@pages/register-page/register-page.component';
import { MemberInfoPageComponent } from '@pages/member-info-page/member-info-page.component';
import { CalculatorPageComponent } from '@pages/calculator-page/calculator-page.component';
import { PrivacyPageComponent } from '@pages/privacy-page/privacy-page.component';
import { ForgetpasswordPageComponent } from '@pages/forgetpassword-page/forgetpassword-page.component';
import { HomeComponent } from '@pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    // redirectTo: 'news/',
    // pathMatch: 'full',
    // component: NewsContentPageComponent,
    // children: [
    //   {
    //       path: '',
    //       // redirectTo: 'news/',
    //       // pathMatch: 'full',
    //       // redirectTo: 'news/',
    //       // pathMatch: 'full',
    //       component: NewsContentPageComponent,
    //   },
    //   {
    //       path: 'news',
    //       redirectTo: 'news/',
    //       pathMatch: 'full',
    //   },
    //   {
    //       path: 'news/:id',
    //       component: NewsContentPageComponent,
    //   },
    // ]
  },
  {
    path: 'news',
    component: NewsContentPageComponent,
  },
  {
    path: 'news/:id',
    component: NewsContentPageComponent,
  },
  {
    path: 'plans',
    component: MainComponent,
    children: [
      {
          path: ':type',
          // redirectTo: ':type/',
          // pathMatch: 'full',
          component: PlanContentPageComponent,
      },
      {
          path: ':type/:id',
          component: PlanContentPageComponent,
      },
    ]
  },
  {
    path: 'calculator',
    component: CalculatorPageComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPageComponent,
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'forgetpassword',
    component: ForgetpasswordPageComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'register',
    component: RegisterPageComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'member',
    component: MemberPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'member/plans',
    component: MemberPlansPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'member/plan',
    component: MemberPlanPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'member/plan/:id',
    component: MemberPlanPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'member/stars',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
          path: ':type',
          redirectTo: ':type/',
          pathMatch: 'full',
      },
      {
          path: ':type/:id',
          component: StarPlansPageComponent,
      },
    ]
  },
  {
    path: 'member/info',
    component: MemberInfoPageComponent,
    canActivate: [AuthGuard],
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
