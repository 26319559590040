import {Action} from '@ngrx/store';

export const SET_UI_MODE: string = 'SET_UI_MODE';
export const CAHNGE_PAGE: string = 'CHANGE_PAGE';
export const SET_SEARCH: string = 'SET_SEARCH';
export const SET_PROVIDER: string = 'SET_PROVIDER';
export const UPDATE_CONFIG: string = 'UPDATE_CONFIG';
export const UPDATE_BANNERS: string = 'UPDATE_BANNERS';
export const UPDATE_PROVIDERS: string = 'UPDATE_PROVIDERS';
export const UPDATE_ADS: string = 'UPDATE_ADS';
export const UPDATE_SELECTED_NEWS_ID: string = 'UPDATE_SELECTED_NEWS_ID';
export const UPDATE_SELECTED_PLAN_ID: string = 'UPDATE_SELECTED_PLAN_ID';

export class SetUiMode implements Action {
  readonly type: string = SET_UI_MODE;
  constructor(public payload?) {}
}
export class ChangePage implements Action {
  readonly type: string = CAHNGE_PAGE;
  constructor(public payload?) {}
}
export class SetSearch implements Action {
  readonly type: string = SET_SEARCH;
  constructor(public payload?) {}
}
export class SetProvider implements Action {
  readonly type: string = SET_PROVIDER;
  constructor(public payload?) {}
}

export class UpdateConfig implements Action {
  readonly type: string = UPDATE_CONFIG;
  constructor(public payload?) {}
}
export class UpdateBanners implements Action {
    readonly type: string = UPDATE_BANNERS;
    constructor(public payload?) {}
}
export class UpdateProviders implements Action {
    readonly type: string = UPDATE_PROVIDERS;
    constructor(public payload?) {}
}
export class UpdateAds implements Action {
    readonly type: string = UPDATE_ADS;
    constructor(public payload?) {}
}

export class UpdateSelectedNewsId implements Action {
    readonly type: string = UPDATE_SELECTED_NEWS_ID;
    constructor(public payload?) {}
}
export class UpdateSelectedPlanId implements Action {
    readonly type: string = UPDATE_SELECTED_PLAN_ID;
    constructor(public payload?) {}
}

export type StateAction =
    | ChangePage
    | SetSearch
    | UpdateConfig
    | UpdateBanners
    | UpdateProviders
    | UpdateAds
    | UpdateSelectedNewsId
    | UpdateSelectedPlanId;
