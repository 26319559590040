import {Component, Input} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-checkboxfield',
    templateUrl: './checkboxfield.component.html',
    styleUrls: ['./checkboxfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormCheckboxFieldComponent extends Field {
    config: FieldConfig;
    group: UntypedFormGroup;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // constructor(){}
    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);
    }
}