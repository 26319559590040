import {Component, Input} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-separator',
    templateUrl: './separator.component.html',
    styleUrls: ['./separator.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormSeparatorComponent extends Field {
    config: FieldConfig;
    group: UntypedFormGroup;

    ngOnInit() {
        this.form.addField(this);
    }
}
