import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { SetUiMode, UpdateSelectedNewsId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { AppState } from '@/store/state';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-member-plans-page',
  templateUrl: './member-plans-page.component.html',
  styleUrls: ['./member-plans-page.component.scss']
})
export class MemberPlansPageComponent implements OnInit {

  plans: any[];

  constructor(
      private toastr: ToastrService,
      public appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      public router: Router,
      private navigation: NavigationService,
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.appService.dataGet('user_plans', {key: 'TeleBro_users_id', value: this.appService.user.id}).then((rtn) => {
      this.plans = rtn.payload.results;
    });
  }

  ngOnDestroy(): void {

  }

  onPlanSelect(plan) {
    this.router.navigate(['member', 'plan', plan.id]);
  }

  onAddClick() {
    this.router.navigate(['member', 'plan']);
  }

  onBackClick() {
    this.router.navigate(['member']);
  }
}
