import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';
import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { ChangePage, SetSearch, SetUiMode, UpdateSelectedNewsId, UpdateSelectedPlanId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { AppState } from '@/store/state';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { ApiService } from '@services/api.service';
import { FormConfig } from '@components/formfields/form-config.interface';
import { async } from '@angular/core/testing';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-calculator-page',
  templateUrl: './calculator-page.component.html',
  styleUrls: ['./calculator-page.component.scss']
})
export class CalculatorPageComponent implements OnInit {
  loginFormConfig: FormConfig;
  sliderObjs: SliderRecord[] = [];

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      public router: Router,
      private navigation: NavigationService,
      private metaTagService: Meta,
      private title: Title,
  ) {
    this.title.setTitle('數據用量計算機 | 估計流動數據用量 - 電訊巴打');
    this.sliderObjs = [
      {
        icon: '/assets/img/calc-whatsapp.png',
        text: '即時通訊App收發文字訊息',
        unit: '個',
        max: 1000,
        value: 0,
        size: 1,
      },
      {
        icon: '/assets/img/calc-whatsapp.png',
        text: '即時通訊App收發相片',
        unit: '個',
        max: 1000,
        value: 0,
        size: 150,
      },
      {
        icon: '/assets/img/calc-whatsapp.png',
        text: '即時通訊App收發影片',
        unit: '個',
        max: 200,
        value: 0,
        size: 5000,
      },
      {
        icon: '/assets/img/calc-whatsapp.png',
        text: '即時通訊App收發語音訊息',
        unit: '個',
        max: 1000,
        value: 0,
        size: 300,
      },
      {
        icon: '/assets/img/calc-music.png',
        text: '串流播放音樂',
        unit: '分鐘',
        max: 500,
        value: 0,
        size: 2500,
      },
      {
        icon: '/assets/img/calc-video.png',
        text: '串流播放 720p 短片',
        unit: '分鐘',
        max: 500,
        value: 0,
        size: 9000,
      },
      {
        icon: '/assets/img/calc-video.png',
        text: '串流播放 1080p 短片',
        unit: '分鐘',
        max: 500,
        value: 0,
        size: 15000,
      },
      {
        icon: '/assets/img/calc-mail.png',
        text: '收發電郵 - 純文字',
        unit: '個',
        max: 1000,
        value: 0,
        size: 15,
      },
      {
        icon: '/assets/img/calc-mail.png',
        text: '收發電郵 - 包括附件',
        unit: '個',
        max: 1000,
        value: 0,
        size: 300,
      },
      {
        icon: '/assets/img/calc-web.png',
        text: '瀏覽網頁',
        unit: '個',
        max: 1000,
        value: 0,
        size: 1000,
      },
      {
        icon: '/assets/img/calc-app.png',
        text: '程式/遊戲下載',
        unit: '個',
        max: 20,
        value: 0,
        size: 50000,
      },
      {
        icon: '/assets/img/calc-socialmedia.png',
        text: '社交網絡上載近況及相片',
        unit: '個',
        max: 1000,
        value: 0,
        size: 1000,
      },
    ];
  }

  ngOnInit(): void {
    this.loginFormConfig = {
      submitText: "登入",
      fields: [
        [{
          name: "name",
          label: "電郵/用戶名",
          type: "text"
        }],
        [{
          name: "password",
          label: "密碼",
          type: "password"
        }],
      ]
    };
    this.store.dispatch(new ChangePage(null));
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {

  }

  onSubmit = async (apiService: ApiService, objRef) => {
    var result = await this.appService.loginByAuth({email: objRef.name, password: objRef.password});
  }

  onFacebookLogin = async () => {

  }

  onForgetPassword = () => {

  }

  onRegister = () => {
    this.router.navigate(['register']);
  }

  onSubmitted($event) {

  }

  reset() {
    this.sliderObjs.forEach((item) => {
      item.value = 0;
    });
  }

  getTotalVolumeInGb = () => {
    var total = 0;
    this.sliderObjs.forEach((item) => {
      total += item.value * item.size;
    });
    var dataVolMb = total / 1000.0;
    var dataVolGb = dataVolMb / 1000.0;

    dataVolGb = dataVolGb / 5.0;
    dataVolGb = Math.ceil(dataVolGb) * 5.0;
    return dataVolGb;
  }

  getTotalVolume = () => {
    var total = 0;
    this.sliderObjs.forEach((item) => {
      total += item.value * item.size;
    });
    var dataVolMb = total / 1000.0;
    var dataVolGb = dataVolMb / 1000.0;
    let dataVolText = dataVolGb > 1 ? (dataVolGb.toFixed(2) + " GB") : (dataVolMb.toFixed(2) + " MB");
    return dataVolText;
  }

  goToPlans() {
    this.store.dispatch(new ChangePage('mobile'));
    this.store.dispatch(new SetUiMode('listview'));
    this.store.dispatch(new SetSearch(this.getTotalVolumeInGb() + " G"));
    this.router.navigateByUrl('/plans/mobile');
  }
}

class SliderRecord {
  icon: string;
  text: string;
  unit: string;
  max: number;
  value: number;
  size: number;
}
