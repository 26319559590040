import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
    @ViewChild(BaseChartDirective) chart2: BaseChartDirective | undefined;

    public barChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            x: {},
            y: {},
            y1: {
                position: 'right',
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
            }
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                anchor: 'end',
                align: 'end'
            }
        }
    };
    public barChartType: ChartType = 'bar';
    public barChartPlugins = [
        DataLabelsPlugin
    ];
    public barChartData: ChartData = {
        labels: [ '2022-04-07', '2022-04-08', '2022-04-09', '2022-04-10', '2022-04-11', '2022-04-12', '2022-04-13' ],
        datasets: [
            { data: [ 2, 8, 4, 1, 8, 2, 9 ], label: '差價額(HKD$1000)', yAxisID: 'y1', type: 'line',},
        { data: [ 165, 159, 180, 181, 156, 155, 140 ], label: '對換總額(HKD$1000)', yAxisID: 'y',backgroundColor: "gold", hoverBackgroundColor: "yellow"},
        ]
    };

    public pieChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                anchor: 'end',
                align: 'end'
            }
        }
    };
    public pieChartType: ChartType = 'pie';
    public pieChartPlugins = [
        DataLabelsPlugin
    ];
    public pieChartData: ChartData = {
        labels: [
            '店舖01',
            '店舖02',
            '店舖03',
            '店舖04',
            '店舖05',
            '店舖06',
            '店舖07',
            '店舖08',
            '店舖09',
            '店舖10',
            '店舖11',
            '店舖12'
          ],
          datasets: [{
            label: '過去七日店舖對換比例',
            data: [300, 50, 100, 150, 220, 310, 15, 80, 70, 45, 85, 25],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 159, 64)',
                'rgb(201, 203, 207)',
                'rgb(153, 102, 255)',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(255, 159, 64)'
            ],
            hoverOffset: 4
          }]
    };

    // events
    public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
        // console.log(event, active);
    }

    public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
        // console.log(event, active);
    }

    public randomize(): void {
        // Only Change 3 values
        this.barChartData.datasets[0].data = [
            Math.round(Math.random() * 100),
            59,
            80,
            Math.round(Math.random() * 100),
            56,
            Math.round(Math.random() * 100),
            40
        ];

        this.chart?.update();
    }
}
