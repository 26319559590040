<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>銷售員列表</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-table #table [onAdd]="onAdd" [dataGetByUrl]="dataGetByUrl" [search]="search" [cols]="cols" [isCreatable]="false"></app-table>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->

<ng-template #formModal let-modal>
    <div class="modal-header">
        <span class="modal-title">{{ editObj ? "修改" : "新增" }}</span>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <app-form [dataUpdateByUrl]="dataUpdateByUrl" [config]="formDef" [editObj]="editObj" (submit)="onSubmitted($event)"></app-form>
    </div>
</ng-template>