<div class="form-group row" [formGroup]="group">
    <label class="col-sm-2 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <div class="col-sm-10">
        <input class="form-control"
            type="text"
            [formControlName]="config.name"
            [style.background]="color"
            [colorPicker]="color"
            [cpAlphaChannel]="config.isAlpha ? 'enabled' : 'disabled'"
            (colorPickerChange)="onColorChange($event)">
    </div>
</div>