import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { SetUiMode, UpdateSelectedNewsId } from '@/store/state/actions';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {
  throttle = 0;
  distance = 2;
  page = 1;
  news = [];

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private router: Router,
      private location: Location,
      private store: Store<StateState>
  ) {}

  ngOnInit(): void {
    this.appService.dataGet('news', null, null, null, null, 'date', 10, (this.page - 1) * 10).then((result) => {
      this.news = result.payload.results;
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
  }

  async onScroll(): Promise<void> {
    this.page++;
    this.appService.dataGet('news', null, null, null, null, 'date', 10, (this.page - 1) * 10).then((result) => {
      this.news.push(...result.payload.results);
    });
  }

  onNewsItemSelect($event, newsItem) {
    $event.preventDefault();
    this.store.dispatch(new UpdateSelectedNewsId(newsItem.id));
    this.router.navigateByUrl('/news/' + newsItem.id);
    this.store.dispatch(new SetUiMode('contentview'));
  }
}
