<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>設定</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-form [objClass]="objClass" [config]="formDef" [editObj]="editObj" (submit)="onSubmitted($event)" #form *ngIf="editObj"></app-form>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->