<div class="row" *ngIf="config.isShow == undefined || config.isShow(form.currentObj)">
  <label class="col-6 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
  <div class="col-6">
    <div class="btn btn-primary file-add" *ngIf="!config.disabled">
      <input #imageInput
        type="file"
        accept="image/*, application/pdf"
        (change)="processFile(imageInput)"
        >
      <i class="fas fa-plus"></i>
    </div>
  </div>
</div>

<div class="row" *ngIf="config.isShow == undefined || config.isShow(form.currentObj)">
  <div class="col-2" *ngFor="let url of files">
    <ng-container *ngIf="url != null">
      <div class="file">
        <img src="{{ url }}" (click)="imageview(url)" *ngIf="url != null && !url.endsWith('.pdf')" />
        <img src="assets/img/pdficon.png" (click)="imageview(url)" *ngIf="url != null && url.endsWith('.pdf')" />
        <div class="file-remove" (click)="imageremove(url)"  *ngIf="!config.disabled"><i class="fas fa-times"></i></div>
      </div>
    </ng-container>

  </div>
  <div class="col-2" *ngFor="let n of uploadingCountArray">
    <div class="file">
      <img src="assets/img/loading.gif" />
    </div>

  </div>
</div>

<div class="row" [formGroup]="group">
  <input class="form-control"
    type="hidden"
    [formControlName]="config.name">
</div>
