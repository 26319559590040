import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';
import { FieldConfig } from '@components/formfields/field-config.interface';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  isDtInitialized: boolean = false;
  @ViewChild('table') table: TableComponent;
  @ViewChild('formModal') public formModal: TemplateRef<any>;
  onAdd = (): void => {
    this.onCreate(this.formModal);
  }
  dataGetByUrl: string = "custapi/v1/service/TeleBro/User/GetSalesList";
  cols: ColumnConfig[];
  search: {key: string, value: string[]}[] = [];

  dataUpdateByUrl: string = "custapi/v1/service/TeleBro/User/UpdateSales";
  objClass: string = "sales";
  formDef: FieldConfig[];
  editObj;

  sortBy = "name";
  sortDir = "asc";
  searchType: string = "客戶名稱";
  searchText: string = "";

  modal: NgbModalRef;
  active = 1;
  public isLoading = false;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.cols = [
      { setting: { name: 'username', title: '用戶名' }, searchable: true, },
      { setting: { name: 'name', title: '全名' }, searchable: true, },
      { setting: { name: 'mobile', title: '電話號碼', orderable: false, }, searchable: true },
      { setting: { name: 'email', title: '電郵' }, searchable: true, },
      { setting: { name: 'checked', title: '已批准', render: function(data) {
          return data == "1" ? '<i class="fas fa-check"></i>' : '';
        } }, searchable: false, },
      { setting: { name: 'approved', title: '已認證', render: function(data) {
          return data == "1" ? '<i class="fas fa-check"></i>' : '';
        } }, searchable: false, },
      { setting: { name: 'status', title: '狀態', render: function(data) {
          switch(data) {
            case "normal":
              return "正常";
            case "disable":
              return "停用";
          }
          return "正常";
        } }, searchable: true, },
      { setting: { title: '動作', orderable: false }, buttons: [
        {
          name: "修改",
          class: "btn-primary pull-right",
          onClick: (dataItem) => this.onEditAccount(this.formModal, dataItem)
        }
      ] },
    ];

    this.search = [];

    this.formDef = [
      {
        name: "thumbnail",
        label: "頭像",
        type: "image",
      },
      {
        name: "name",
        label: "全名",
        type: "text"
      },
      {
        name: "username",
        label: "用戶名",
        type: "text"
      },
      {
        name: "mobile",
        label: "電話號碼",
        type: "text"
      },
      {
        name: "email",
        label: "電郵地址",
        type: "text"
      },
      {
        name: "status",
        label: "狀態",
        type: "select",
        select: [
          {key: "normal", text: "正常"},
          {key: "disable", text: "停用"},
        ]
      },
      {
        name: "whatsapp",
        label: "WhatsApp",
        type: "text"
      },
      {
        name: "telegram",
        label: "Telegram",
        type: "text"
      },
      {
        name: "staff_card",
        label: "職員証",
        type: "image",
      },
      {
        name: "staff_name",
        label: "職員証名稱",
        type: "text",
      },
      {
        name: "company",
        label: "公司名稱",
        type: "text",
      },
      {
        name: "checked",
        label: "已批准",
        type: "checkbox",
      },
      {
        name: "approved",
        label: "已認證",
        type: "checkbox",
      },
      {
        name: "show_cross_plan",
        label: "顯示其他計劃",
        type: "checkbox",
      },
      {
        name: "type",
        type: "hidden",
        value: "sales"
      },
      {
        name: "method",
        type: "hidden",
        value: "local"
      },
    ];
  }

  onCreate(formModal) {
    this.editObj = undefined;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onEditAccount(formModal, dataItem) {
    this.editObj = dataItem;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onSubmitted(result) {
    this.table.onTableRefresh();
    this.modal.dismiss('Saved');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
