import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { SetUiMode, UpdateSelectedNewsId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { AppState } from '@/store/state';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { ApiService } from '@services/api.service';
import { FormConfig } from '@components/formfields/form-config.interface';

@Component({
  selector: 'app-member-plan-page',
  templateUrl: './member-plan-page.component.html',
  styleUrls: ['./member-plan-page.component.scss']
})
export class MemberPlanPageComponent implements OnInit {
  planFormConfig: FormConfig;
  providers: any[];
  editObj;
  editObjId;

  isReady = false;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      public router: Router,
      private navigation: NavigationService,
  ) {}

  ngOnInit(): void {
    this.editObjId = this.route.snapshot.paramMap.get('id');
  }

  async ngAfterViewInit() {
    this.providers = (await this.appService.dataGet('providers')).payload.results;
    if(this.editObjId) {
      var r = (await this.appService.dataGet('user_plans', null, [
        {key: 'id', value: [this.editObjId.toString()]},
        {key: 'TeleBro_users_id', value: [this.appService.user.id.toString()]},
      ])).payload.results;
      if(r.length > 0) { this.editObj = r[0]; }
    }
    this.planFormConfig = {
      fields: [
        [{
          name: "type",
          label: "計劃種類",
          type: "buttonselect",
          select: [
            {key: '1', text: "固網寛頻"},
            {key: '2', text: "家居5G寬頻"},
            {key: '3', text: "手機數據"},
            {key: '4', text: "收費電視"},
          ],
          validation: [Validators.required]
        }],
        [{
          name: "title",
          label: "計劃標題",
          type: "text",
          validation: [Validators.required]
        }],
        [{
          name: "TeleBro_providers_id_1",
          label: "電訊商",
          type: "select",
          valueBuilder: (objRef) => {
            return this.editObj ? this.editObj.TeleBro_providers_id : '';
          },
          isShow: (objRef) => {
            return objRef.type == '1';
          },
          selectBuilder: (objRef) => {
            return this.providers.filter(p => p.category == 'fixedwired').map(p => {
              return {
                key: p.id,
                text: p.name
              }
            })
          },
          onSave: (objRef, input) => {
            if(objRef.type == '1') { objRef['TeleBro_providers_id'] = input; }
            delete objRef['TeleBro_providers_id_1'];
          },
        }],
        [{
          name: "TeleBro_providers_id_2",
          label: "電訊商",
          type: "select",
          valueBuilder: (objRef) => {
            return this.editObj ? this.editObj.TeleBro_providers_id : '';
          },
          isShow: (objRef) => {
            return objRef.type == '2';
          },
          selectBuilder: (objRef) => {
            return this.providers.filter(p => p.category == 'fixedcellular').map(p => {
              return {
                key: p.id,
                text: p.name
              }
            })
          },
          onSave: (objRef, input) => {
            if(objRef.type == '2') { objRef['TeleBro_providers_id'] = input; }
            delete objRef['TeleBro_providers_id_2'];
          }
        }],
        [{
          name: "TeleBro_providers_id_3",
          label: "電訊商",
          type: "select",
          valueBuilder: (objRef) => {
            return this.editObj ? this.editObj.TeleBro_providers_id : '';
          },
          isShow: (objRef) => {
            return objRef.type == '3';
          },
          selectBuilder: (objRef) => {
            return this.providers.filter(p => p.category == 'fixedwired').map(p => {
              return {
                key: p.id,
                text: p.name
              }
            })
          },
          onSave: (objRef, input) => {
            if(objRef.type == '3') { objRef['TeleBro_providers_id'] = input; }
            delete objRef['TeleBro_providers_id_3'];
          }
        }],
        [{
          name: "TeleBro_providers_id_4",
          label: "電訊商",
          type: "select",
          valueBuilder: (objRef) => {
            return this.editObj ? this.editObj.TeleBro_providers_id : '';
          },
          isShow: (objRef) => {
            return objRef.type == '4';
          },
          selectBuilder: (objRef) => {
            return this.providers.filter(p => p.category == 'tv').map(p => {
              return {
                key: p.id,
                text: p.name
              }
            })
          },
          onSave: (objRef, input) => {
            if(objRef.type == '4') { objRef['TeleBro_providers_id'] = input; }
            delete objRef['TeleBro_providers_id_4'];
          }
        }],
        [{
          name: "cost",
          label: "月費",
          type: "number"
        }],
        [{
          name: "start_date",
          label: "登記日期",
          type: "date",
        }],
        [{
          name: "end_date",
          label: "到期日期",
          type: "date"
        }],
        [{
          name: "label",
          label: "銷售員資料",
          type: "label"
        }],
        [{
          name: "sales_name",
          label: "銷售員名稱",
          type: "text"
        }],
        [{
          name: "sales_mobile",
          label: "銷售員電話",
          type: "text"
        }],
        [{
          name: "TeleBro_users_id",
          type: "hidden",
          value: this.appService.user.id
        }],
      ]
    }
    this.isReady = true;
  }

  ngOnDestroy(): void {

  }

  onSubmitted($event) {
    this.toastr.success(this.editObjId ? '資料更新成功' : '資料新增成功');
    this.router.navigate(['member', 'plans']);
  }
}
