import { SiteConfig } from "@services/app.service";

export default <StateState>{
  pageType: "news",
  uiMode: "wwwroot",
  searchWord: null,
  providerId: null,

  newsId: null,
  planId: null,

  config: null,
  banners: [],
  providers: [],
  ads: []
};

export interface StateState {
  pageType: string;
  uiMode: string;
  searchWord: string;
  providerId: number;

  newsId: number;
  planId: number;

  config: SiteConfig;
  banners: any[];
  providers: any[];
  ads: any[];
}
