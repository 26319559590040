<!-- Main content -->
<section class="content" style="padding: 0">
    <!-- Default box -->
    <div class="container-fluid" style="padding: 0; text-align: center">
      <img src="/assets/img/ic_launcher-web.png" style="max-width: 250px;" />

      <a href="https://apps.apple.com/hk/app/%E9%9B%BB%E8%A8%8A%E5%B7%B4%E6%89%93/id1631767068"><img class="m-2" src="/assets/img/appstore.webp" /></a>
      <a href="https://play.google.com/store/apps/details?id=com.kinandfung.telebro"><img class="m-2" src="/assets/img/googleplay.webp" /></a>

      <div class="ml-5 mr-5" style="text-align: left;">
        <h1>轉台優惠、家居寬頻比較、邊個台最抵，一APP睇晒！</h1>

        <h2>・想搵最平電話月費或寬頻比較？即刻幫到你！</h2>
        <p>想要節省通訊開支？「電訊巴打」APP專為您提供「最平電話月費」的資訊，同時也是您進行「寬頻比較」的強大工具。我們的目標好簡單明確：幫助您在繁多的電訊公司中，輕鬆找到「最平電話月費」，同時聯絡我們的不同鎖售員可以進行不同公司「寬頻比較」，確保您的寬頻收費既高效又經濟。</p>
        <div class="text-center"><img src="/assets/img/image-01.jpg" style="width: 540px;" /></div>

        <!-- <h2>・有問必答！</h2> -->
        <!-- <p>「最平電話月費」不再是尋寶遊戲，因為「電訊巴打」將所有信息一網打盡。我們的「寬頻比較」系統讓您不僅對價格有了全面的了解，還包括了服務品質和用戶評價。這樣一來，每當您想要更新您的手機或寬頻服務時，「最平電話月費」和「寬頻比較」將是您最可靠的參考。</p> -->
        <div class="text-center"><img src="/assets/img/image-02.jpg" style="width: 540px;" /></div>

        <!-- <h2>・簽約地點，由您話事！</h2> -->
        <!-- <p>別讓複雜的價格和套餐條款讓您頭痛，「電訊巴打」為您提供了一個簡單的解決方案。透過我們的APP，您不僅能發現「最平電話月費」，還能進行全面的「寬頻比較」，確保您的每一分錢都花得其所。不要再等了，立即下載「電訊巴打」，開始您節省與智選的旅程吧！</p> -->
        <div class="text-center"><img src="/assets/img/image-03.jpg" style="width: 540px;" /></div>
      </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
