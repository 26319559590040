<div class="form-group row" [formGroup]="group">
    <label class="col-sm-2 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <div class="col-sm-10">
        <textarea class="form-control"
            type="text"
            [attr.placeholder]="config.placeholder"
            [formControlName]="config.name">
        </textarea>
        <label class="remark" *ngIf="config.remark">{{ config.remark }}</label>
    </div>
</div>