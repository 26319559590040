import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { SetUiMode, UpdateSelectedNewsId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { AppState } from '@/store/state';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { ApiService } from '@services/api.service';
import { FormConfig } from '@components/formfields/form-config.interface';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
  loginFormConfig: FormConfig;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      public router: Router,
      private navigation: NavigationService,
  ) {}

  ngOnInit(): void {
    this.loginFormConfig = {
      submitText: "確定",
      fields: [
        [{
          name: "username",
          label: "用戶名",
          type: "text",
          validation: [Validators.required]
        }],
        [{
          name: "email",
          label: "電郵",
          type: "text",
          validation: [Validators.required]
        }],
        [{
          name: "password",
          label: "密碼",
          type: "password",
          validation: [Validators.required]
        }],
        [{
          name: "repassword",
          label: "重新輸入密碼",
          type: "password",
          validation: [Validators.required]
        }],
        [{
          name: "mobile",
          label: "電話號碼",
          type: "text",
        }],
        [{
          name: "name",
          label: "姓名",
          type: "text",
        }],
      ],
    };
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {

  }

  onSubmit = async (apiService: ApiService, objRef) => {
    if(objRef.password !== objRef.repassword) {
      this.toastr.error('兩個密碼不相乎');
      return false;
    }
    var result = await this.appService.registerByAuth(objRef);
  }

  onBack = () => {
    this.router.navigate(['login'])
  }

  onSubmitted($event) {

  }
}
