<div class="row">
  <div class="col-12 valuebox" *ngIf="!hideValue">{{ valueStr }}</div>
</div>
<div class="keys">
  <div class="row">
    <div class="col-6"><div (click)="onClearClick()">清除</div></div>
    <div class="col-6"><div (click)="onConfirmClick()">確認</div></div>
  </div>
  <div class="row">
    <div class="col-4"><div (click)="onClick(7)">7</div></div>
    <div class="col-4"><div (click)="onClick(8)">8</div></div>
    <div class="col-4"><div (click)="onClick(9)">9</div></div>
  </div>
  <div class="row">
    <div class="col-4"><div (click)="onClick(4)">4</div></div>
    <div class="col-4"><div (click)="onClick(5)">5</div></div>
    <div class="col-4"><div (click)="onClick(6)">6</div></div>
  </div>
  <div class="row">
    <div class="col-4"><div (click)="onClick(1)">1</div></div>
    <div class="col-4"><div (click)="onClick(2)">2</div></div>
    <div class="col-4"><div (click)="onClick(3)">3</div></div>
  </div>
  <div class="row">
    <div class="col-4"><div (click)="onDotClick()">.</div></div>
    <div class="col-4"><div (click)="onClick(0)">0</div></div>
    <div class="col-4"><div (click)="onBackspaceClick()">C</div></div>
  </div>
  <div class="row" *ngIf="mode != 'number'">
    <div class="col-12"><div (click)="onSpaceClick()">空格</div></div>
  </div>
</div>
