<div class="row main-wrapper m-0" style="height: 100%;overflow-y: scroll;">
  <div style="margin: auto; max-width: 1280px; padding: 0px">
    <div style="margin: auto; max-width: 960px;">
      <ngb-carousel *ngIf="selectedPlanType != 'fixedcellular' && selectedPlanType != 'fixedwired' && banners" [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="15000">
        <ng-template ngbSlide *ngFor="let banner of banners">
          <div class="picsum-img-wrapper" style="cursor: pointer;" (click)="bannerClick(banner)">
            <img [src]="banner.image" alt="banner">
          </div>
        </ng-template>
      </ngb-carousel>
      <ngb-carousel *ngIf="selectedPlanType == 'fixedcellular' && bannersFixedCellular" [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="15000">
        <ng-template ngbSlide *ngFor="let banner of bannersFixedCellular">
          <div class="picsum-img-wrapper" style="cursor: pointer;" (click)="bannerClick(banner)">
            <img [src]="banner.image" alt="banner">
          </div>
        </ng-template>
      </ngb-carousel>
      <ngb-carousel *ngIf="selectedPlanType == 'fixedwired' && bannersFixedWired" [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="15000">
        <ng-template ngbSlide *ngFor="let banner of bannersFixedWired">
          <div class="picsum-img-wrapper" style="cursor: pointer;" (click)="bannerClick(banner)">
            <img [src]="banner.image" alt="banner">
          </div>
        </ng-template>
      </ngb-carousel>
  </div>
    <div style="margin-top: 1rem; display: flex; flex-direction: row; gap: 0.5rem; align-items: center; justify-content: center;">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; border: 1px solid #3085DB; border-radius: 0.5rem; width: 8rem; height: 8rem; cursor: pointer;" (click)="onChangePlanType('mobile')">
        <img [src]="selectedPlanType == 'mobile' ? 'assets/img/botbtn-selected-mobile.png' : 'assets/img/botbtn-unselect-mobile.png'" />
        <div style="margin-top: 0.75rem; font-size: 1.1rem;" [style]="selectedPlanType == 'mobile' ? 'color: #3085DB;' : ''">手機數據</div>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; border: 1px solid #3085DB; border-radius: 0.5rem; width: 8rem; height: 8rem; cursor: pointer;" (click)="onChangePlanType('fixedcellular')">
        <img [src]="selectedPlanType == 'fixedcellular' ? 'assets/img/botbtn-selected-home.png' : 'assets/img/botbtn-unselect-home.png'" />
        <div style="margin-top: 0.75rem; font-size: 1.1rem;" [style]="selectedPlanType == 'fixedcellular' ? 'color: #3085DB;' : ''">家居5G寛頻</div>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; border: 1px solid #3085DB; border-radius: 0.5rem; width: 8rem; height: 8rem; cursor: pointer;" (click)="onChangePlanType('fixedwired')">
        <img [src]="selectedPlanType == 'fixedwired' ? 'assets/img/botbtn-selected-homelan.png' : 'assets/img/botbtn-unselect-homelan.png'" />
        <div style="margin-top: 0.75rem; font-size: 1.1rem;" [style]="selectedPlanType == 'fixedwired' ? 'color: #3085DB;' : ''">固網寛頻</div>
      </div>
    </div>
    <div class="tagslist">
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; border-radius: 0.5rem; cursor: pointer;" [style]="{'background-color' : selectedTag == null ? '#72aeea' : '#CCCCCC'}" (click)="onChangeTag(null)">
        <div style="margin: 0.5rem; font-size: 0.9rem; color: black;">全部計劃</div>
      </div>
      <div *ngFor="let tag of tags" style="display: flex; flex-direction: column; align-items: center; justify-content: center; border-radius: 0.5rem; cursor: pointer;" [style]="{'background-color' : selectedTag == tag ? '#72aeea' : '#CCCCCC'}" (click)="onChangeTag(tag)">
        <div style="margin: 0.5rem; font-size: 0.9rem; color: black;">{{ tag.name }}</div>
      </div>
    </div>
    <div style="margin-top: 1rem;">
      <div *ngFor="let provider of providers" style="margin-bottom: 0.5rem">
        <div *ngIf="plans[provider.id] && plans[provider.id].length > 0" class="planlist" style="display: flex; flex-direction: row; gap: 0.5rem; overflow-x: scroll; white-space: nowrap; align-items: stretch; margin: auto">
          <div style="width: 0.5rem"></div>
          <div *ngFor="let n of plans[provider.id]; let i = index" style="width: 45vw; max-width: 16rem;" [style]="{'background-color': (n.related.providers && n.related.providers.length > 0 ? n.related.providers[0].color_code : 'white'), 'color': 'white'}">
            <div style="background: rgb(0,0,0); background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); height: 100%">
            <a [href]="'/plans/' + type + '/' + n.id" >
              <div><img *ngIf="n.thumbnail" src="{{ n.thumbnail }}" style="width: 45vw; max-width: 16rem; height: 45vw; max-height: 16rem;" /></div>
              <div style="margin: 0" [style]="{'color': 'white', 'position': 'relative'}">
              <div class="salesicon" style="border: 1px solid black; border-radius: 200px; width: 4rem; height: 4rem; position: absolute; top: 0px; right: 0px; background-image: url('{{ n.related.sales && n.related.sales.length > 0 && n.related.sales[0].thumbnail? n.related.sales[0].thumbnail : salesicon }}'); background-size: cover; background-position: center;"></div>
              <!-- <div class="salesverify" style="position: absolute; top: 0px; right: 4.5rem; font-size: 80%; line-height: 180%;" *ngIf="n.related.sales && n.related.sales.length > 0 && n.related.sales[0].approved">銷售員已認證</div> -->
              <div [style]="{'background-color': (n.related.providers && n.related.providers.length > 0 ? n.related.providers[0].color_code : 'white'), 'color': 'white'}">
                <span style=" border-top-left-radius: 0.5rem; display: inline-block; ">
                  <span class="mx-2">
                    {{ n.related.providers && n.related.providers.length > 0 ?n.related.providers[0].name : '' }}
                  </span>
                  <span *ngIf="n.boost" class=" px-2 salesplate" style="color: gold; background-color: black; ">
                    星級專員
                  </span>
                </span>
              </div>
              <div class="pl-2 pr-2 plantitle">
                <div class="mb-0 pt-1 title" style="white-space: normal;">{{ n.title }}</div>
              </div>
            </div>
            </a>
          </div>
          </div>
          <div style="width: 0.5rem"></div>
        </div>
      </div>
    </div>
    <div style="text-align: center; font-size: 1.25rem; margin-top: 1rem;">主頁</div>
      <a *ngFor="let n of news" [href]="'/news/' + n.id">
        <div class="news-item mt-2" style="display: flex; flex-direction: row;">
            <img [src]="n.thumbnail"  class="thumbnail" style="display: block; object-fit: cover;" />
          <div class="textgroup">
            <div class="newstitle">{{ n.title }}</div>
            <div class="tag">{{ n.tags }}</div>
          </div>
        </div>
      </a>
</div>

<div class="back-btn" (click)="onClose()" *ngIf="newsId != null">
  <img src="/assets/img/btn-close.png" />
</div>

<ng-template #shareModal let-modal>
  <div class="modal-header">
      <span class="modal-title">分享到</span>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body">
    <share-buttons [theme]="'modern-dark'"
      [include]="['whatsapp','facebook','twitter','linkedin','telegram','messenger','line','sms','email','print','copy']"
      [title]="shareTitle"
      [description]="shareDescription"
      [autoSetMeta]="true"
    ></share-buttons>
  </div>
</ng-template>
