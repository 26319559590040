import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormComponent } from '@components/form/form.component';
import { FormButtonFieldComponent } from '../buttonfield/buttonfield.component';
import { FormButtonSelectFieldComponent } from '../buttonselectfield/buttonselectfield.component';
import { FormCheckboxFieldComponent } from '../checkboxfield/checkboxfield.component';
import { FormColorFieldComponent } from '../colorfield/colorfield.component';
import { FormDateFieldComponent } from '../datefield/datefield.component';
import { FormDateTimeFieldComponent } from '../datetimefield/datetimefield.component';
import { FormDropdownFieldComponent } from '../dropdownfield/dropdownfield.component';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';
import { FormHiddenFieldComponent } from '../hiddenfield/hiddenfield.component';
import { FormImageFieldComponent } from '../imagefield/imagefield.component';
import { FormLabelFieldComponent } from '../labelfield/labelfield.component';
import { FormMultiFileFieldComponent } from '../multifilefield/multifilefield.component';
import { FormMultiSelectFieldComponent } from '../multiselectfield/multiselectfield.component';
import { FormNumberFieldComponent } from '../numberfield/numberfield.component';
import { FormPasswordFieldComponent } from '../password/password.component';
import { FormRichTextFieldComponent } from '../richtextfield/richtextfield.component';
import { FormSelectFieldComponent } from '../selectfield/selectfield.component';
import { FormSeparatorComponent } from '../separator/separator.component';
import { FormTabFieldComponent } from '../tabfield/tabfield.component';
import { FormTextAreaComponent } from '../textarea/textarea.component';
import { FormTextAutoCompleteFieldComponent } from '../textautocompletefield/textautocompletefield.component';
import { FormTextFieldComponent } from '../textfield/textfield.component';

const components: {[type: string]: Type<Field>} = {
    text: FormTextFieldComponent,
    textautocomplete: FormTextAutoCompleteFieldComponent,
    button: FormButtonFieldComponent,
    label: FormLabelFieldComponent,
    number: FormNumberFieldComponent,
    password: FormPasswordFieldComponent,
    textarea: FormTextAreaComponent,
    image: FormImageFieldComponent,
    multifile: FormMultiFileFieldComponent,
    hidden: FormHiddenFieldComponent,
    checkbox: FormCheckboxFieldComponent,
    richtext: FormRichTextFieldComponent,
    select: FormSelectFieldComponent,
    buttonselect: FormButtonSelectFieldComponent,
    multiselect: FormMultiSelectFieldComponent,
    dropdown: FormDropdownFieldComponent,
    date: FormDateFieldComponent,
    datetime: FormDateTimeFieldComponent,
    color: FormColorFieldComponent,
    tab: FormTabFieldComponent,
    separator: FormSeparatorComponent,
  };

@Directive({
    exportAs: 'dynamicField',
  selector: '[dynamicField]',
})
export class DynamicFieldDirective extends Field implements OnChanges, OnInit {
  @Input() form: FormComponent;
  @Input() editObj: object;
  @Input() currentObj: object;
  @Input() config: FieldConfig;
  @Input() group: UntypedFormGroup;
  component: ComponentRef<Field>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  )  { super(); }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit() {
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.form = this.form;
    this.component.instance.currentObj = this.currentObj;
    this.component.instance.editObj = this.editObj;
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
