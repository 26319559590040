<ngb-carousel *ngIf="banners" [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="15000">
  <ng-template ngbSlide *ngFor="let banner of banners">
    <div class="picsum-img-wrapper" style="cursor: pointer;" (click)="bannerClick(banner)">
      <img [src]="banner.image" alt="banner">
    </div>
  </ng-template>
</ngb-carousel>

<div>
  <div style="border: 1px solid black; border-radius: 5px; background-color: lightgray;">
    <div class="m-0 p-1" style="display:flex; border-bottom: 1px solid grey">
      <i class="fas fa-search m-2"></i>
      <input style="background-color: transparent; border: none; flex-grow: 1;" placeholder="請輸入..." [(ngModel)]="searchword" (keyup.enter)="onSearch()" />
      <button class="btn btn-primary pt-1 pb-1" (click)="onSearch()">搜尋</button>
    </div>
    <div style="display: flex; overflow-x: scroll; -ms-overflow-style: none; scrollbar-width: none; width: 100%;" class="p-1 providers-wrapper">
      <div style="display: flex; justify-content: center; flex: 1;">
        <div class="m-1 mr-2" style="word-break:keep-all;" [style]="{'color': selectedProviderId == null ? 'rgb(33, 150, 243)': 'black'}" (click)="onProviderSelect(null)">所有電訊商</div>
        <div class="m-1 mr-2" style="word-break:keep-all;" *ngFor="let provider of providers" [style]="{'color': selectedProviderId == provider.id ? 'rgb(33, 150, 243)': 'black'}" (click)="onProviderSelect(provider)">{{ provider.name }}</div>
      </div>
    </div>
  </div>
</div>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid p-0">
      <div infinite-scroll
        class="infinite-scroll"
        [scrollWindow]="false"
        [infiniteScrollDistance]="distance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onScroll()">
        <div *ngFor="let n of news; let i = index">
          <a [href]="'/plans/' + type + '/' + n.id" (click)="onNewsItemSelect($event, n)" ><div class="news-item mt-2" style="margin: 0" [style]="{'background-color': (n.related.providers && n.related.providers.length > 0 ? n.related.providers[0].color_code : 'white'), 'color': 'white', 'position': 'relative'}">
            <div style="border: 1px solid black; border-radius: 200px; width: 4rem; height: 4rem; position: absolute; top: 0px; right: 0px; background-image: url('{{ n.related.sales && n.related.sales.length > 0 && n.related.sales[0].thumbnail? n.related.sales[0].thumbnail : salesicon }}'); background-size: cover; background-position: center;"></div>
            <div style="position: absolute; top: 0px; right: 4.5rem; font-size: 80%; line-height: 180%;" *ngIf="n.related.sales && n.related.sales.length > 0 && n.related.sales[0].approved">銷售員已認證</div>
            <div class="">
              <span style=" border-top-left-radius: 0.5rem; display: inline-block; ">
                <span class="mx-2">
                  {{ n.related.providers && n.related.providers.length > 0 ?n.related.providers[0].name : '' }}
                </span>
                <span *ngIf="n.boost" class=" px-2" style="color: gold; background-color: black;  display: inline-block;">
                  星級專員
                </span>
              </span>
            </div>
            <div class="pl-2 pr-2" style="background: rgb(0,0,0); background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);">
              <div class="mb-0 pt-1 title">{{ n.title }}</div>
              <div class="tagline">- {{ n.tagline1 }}</div>
              <div class="tagline">- {{ n.tagline2 }}</div>
              <div style="float: right"><i class="fa-star" [class]="isPlanStar(n) ? 'fas' : 'far'" (click)="onStarClick(n)" style="font-size: 125%;"></i></div>
              <div class="tagline">- {{ n.tagline3 }}</div>
              <div class="row pt-2 pb-2">
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 0">{{ n.tags.split(',')[0] }}</div></div>
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 1">{{ n.tags.split(',')[1] }}</div></div>
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 2">{{ n.tags.split(',')[2] }}</div></div>
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 3">{{ n.tags.split(',')[3] }}</div></div>
                <div class="col"><div class="tag" *ngIf="n.tags && n.tags.split(',').length > 4">{{ n.tags.split(',')[4] }}</div></div>
              </div>
            </div>
          </div>
          </a>
          <div *ngIf="(i+1)%5 == 0 && ads && ads.length > 0" class="mt-2">
            <div>
              <a [attr.href]="ads[((i+1)/5)%ads.length].link" target="_blank" style="cursor: pointer">
                <img [src]="ads[((i+1)/5)%ads.length].image" style="width: 100%;" />
              </a>
            </div>
          </div>
          <!-- <div *ngIf="(i+1)%8 == 0" class="mt-2">
            <div>
              <ng-adsense *ngIf="isBrowserOnly"></ng-adsense>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
