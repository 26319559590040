<form [formGroup]="form" (submit)="handleSubmit($event)" *ngIf="!config.compact">
    <div class="row" *ngFor="let fieldRow of config.fields;">
      <div *ngFor="let field of fieldRow;" [ngClass]="'col-' + (12 / fieldRow.length)">
        <ng-container
            dynamicField
            [form]="thisComponent"
            [config]="field"
            [group]="form"
            [editObj]="editObj"
            [currentObj]="currentObj"
            #dynamicFields>
        </ng-container>
      </div>
    </div>
    <app-button
        *ngIf="config.hideSubmitButton !== true"
        [type]="'submit'"
        [block]="true"
        [loading]="isLoading">
        {{ config.submitText ? config.submitText : config.onSubmitText ? config.onSubmitText(editObj) : editObj == null ? "新增" : "修改" }}
    </app-button>
</form>

<form [formGroup]="form" (submit)="handleSubmit($event)" *ngIf="config.compact">
    <div class="row" *ngFor="let fieldRow of config.fields;">
        <div *ngFor="let field of fieldRow;" [ngClass]="'col-' + (6 / fieldRow.length)">
            <ng-container
                dynamicField
                [form]="thisComponent"
                [config]="field"
                [group]="form"
                [editObj]="editObj"
                [currentObj]="currentObj">
            </ng-container>
        </div>
    </div>
    <app-button
        *ngIf="config.hideSubmitButton !== true"
        [type]="'submit'"
        [block]="true"
        [loading]="isLoading">
        {{ config.submitText ? config.submitText : editObj == null ? "新增" : "修改" }}
    </app-button>
</form>
