<div class="card">
    <div class="card-header">
        <h3 class="card-title">
            <app-button (click)="onAddRecord()" *ngIf="isCreatable">新增</app-button>
        </h3>

        <div class="card-tools">
            <div class="input-group">
                <div class="input-group-prepend" ngbDropdown>
                    <button type="button" class="btn btn-primary" id="searchDropdown" ngbDropdownToggle>
                        {{ searchTypeTitle }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="searchDropdown">
                        <button ngbDropdownItem *ngFor="let searchCol of searchCols; index as i" (click)="onSearchDropdownPick(searchCol.setting.name)">{{ searchCol.setting.title }}</button>
                    </div>
                </div>
                
                <input type="text" class="form-control" #searchTextfield>
                <span class="input-group-append">
                    <button type="button" class="btn btn-primary" (click)="onSearchButtonPress(searchTextfield.value)">搜尋</button>
                </span>
            </div>
        </div>
    </div>
    <div class="card-body">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="display">
            <tbody *ngIf="dataItems?.length >= 0">
                <tr *ngFor="let dataItem of dataItems; index as i" [class]="i % 2 == 0 ? 'odd' : 'even'">
                    <td *ngFor="let col of cols">
                        <span *ngIf="col.setting.name" [innerHTML]="col.setting.render != null ? col.setting.render(dataItem[col.setting.name]) : dataItem[col.setting.name]">
                        </span>
                        <span *ngIf="col.setting.name == undefined && col.buttons">
                            <button *ngFor="let button of col.buttons" class="btn btn-mini" [ngClass]="button.class" (click)="button.onClick(dataItem)">{{ button.name }}</button>
                        </span>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="dataItems?.length == 0">
                <tr>
                    <td [attr.colspan]="cols.length" class="no-data-available">沒有資料!</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- /.card-body -->
</div>
