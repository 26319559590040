import {ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { DynamicFieldDirective } from '@components/formfields/dynamic-field/dynamic-field.directive';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { Field } from '@components/formfields/field.interface';
import { FormConfig } from '@components/formfields/form-config.interface';
import { FormTabFieldComponent } from '@components/formfields/tabfield/tabfield.component';
import { FormTextFieldComponent } from '@components/formfields/textfield/textfield.component';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnDestroy {
    form: UntypedFormGroup;
    isLoading: boolean = false;
    thisComponent: FormComponent = this;
    fields: Field[] = [];
    currentObj: any = {};

    @Input() objClass?: string;
    @Input() dataCreateByUrl?: string;
    @Input() dataUpdateByUrl?: string;
    @Input() config: FormConfig;
    @Input() editObj?: any;
    @Input() onSubmit?: (apiService: ApiService, object: any) => Promise<boolean>;

    @Output() submit: EventEmitter<any> = new EventEmitter<any>();

    isEsculateFormUpdate: boolean = true;
    valueChangeSubscribe: Subscription;

    get fieldArray() { return this.config.fields.reduce((previous, current, index) => {
      previous.push(...current);
      return previous;
    }, []); }
    get controls() { return this.fieldArray.filter(({type}) => type !== 'button'); }
    get changes() { return this.form.valueChanges; }
    get valid() { return this.form.valid; }
    get value() { return this.form.value; }

    constructor(private fb: UntypedFormBuilder,
        private toastr: ToastrService,
        private apiService: ApiService,
        private appService: AppService,
        public viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        this.formInit();
    }

    formInit() {
        this.form = this.createGroup();
        this.ngOnChanges();
    }

    ngOnChanges() {
        if (this.form) {
            const controls = Object.keys(this.form.controls);
            const configControls = this.controls.map((item) => item.name);

            controls
                .filter((control) => !configControls.includes(control))
                .forEach((control) => this.form.removeControl(control));

            configControls
                .filter((control) => !controls.includes(control))
                .forEach((name) => {
                    const config = this.fieldArray.find((control) => control.name === name);
                    this.form.addControl(name, this.createControl(config));
                });

            this.controls.filter((control) => control.tab).forEach((c) => {
                c.tab.forEach(t => {
                    t.config.fields.forEach(fr => {
                        fr.forEach(f => {
                          var control = this.createControl(f);
                          this.form.addControl(f.name, control);
                          if(this.editObj) {
                              f.value = this.editObj[f.name];
                              control.setValue(this.editObj[f.name]);
                          } else {
                            f.value = null;
                            control.setValue(null);
                          }
                        });
                    });
                });
            });

            this.controls.filter((control) => control.entriesconfig && control.entriesconfig.fields).forEach(f => {
                var control = this.createControl(f);
                this.form.addControl(f.name, control);
                if(this.editObj) {
                    f.value = this.editObj[f.name];
                    control.setValue(this.editObj[f.name]);
                } else {
                  f.value = null;
                  control.setValue(null);
                }
              });

            if(this.editObj) {
                controls
                    .filter((control) => configControls.includes(control))
                    .forEach((control) => {
                        this.form.controls[control].setValue(this.editObj[control], {emitEvent: true});
                    });
            }
        }
    }

    createGroup() {
        const group = this.fb.group({});
        this.controls.forEach(control => {
            var newControl = this.createControl(control);
            group.addControl(control.name, newControl);
            if(this.editObj) {
                control.value = this.editObj[control.name];
                newControl.setValue(this.editObj[control.name]);
            }

            if(control.tab) {
                control.tab.forEach(t => {
                    t.config.fields.forEach(fr => {
                      fr.forEach(f => {
                        var control = this.createControl(f);
                        group.addControl(f.name, control);
                        if(this.editObj) {
                            f.value = this.editObj[f.name];
                            control.setValue(this.editObj[f.name]);
                        }
                      });
                    });
                });
            }
        });
        this.valueChangeSubscribe = group.valueChanges.subscribe(async value => {
            this.currentObj = group.getRawValue();
            if(this.config.onValueChange) {
                await this.config.onValueChange(this.currentObj);
            }
            if(this.isEsculateFormUpdate) {
                this.fields.forEach(f => f.onCurrentObjChange(this.currentObj));
                this.controls.forEach(c => {
                  if(c.onFormUpdate) { c.onFormUpdate(this.currentObj); }
                })
            }
        });
        return group;
    }

    createControl(config: FieldConfig) {
        var { disabled, validation, value } = config;
        if(config.type == "number") {
          if(!validation) { validation = []; }
          validation.push(Validators.pattern("[0-9\.]*"));
        }
        var control = this.fb.control({ disabled, value }, validation);
        if(config.value) {
          control.setValue(config.value);
        }
        if(config.valueBuilder) {
          control.setValue(config.valueBuilder(this.editObj));
        }
        if(config.disabled) {
          control.disable();
        }
        return control;
    }

    async handleSubmit(event?: Event) {
      if(event) {
        event.preventDefault();
        event.stopPropagation();
      }

        this.fields.forEach(f => {
            if(f.onSubmitForm) { f.onSubmitForm(this.form); }
        });

        if (this.form.valid) {
            this.isLoading = true;

            var updateObj = {};

            // for(let cKey in this.config.fields) {
            //     var c = this.config.fields[cKey];
            //     if(this.form.value[c.name] !== undefined) {
            //         if(c.type == "tab") {
            //             c.tab.find(t => t.key == this.form.value[c.name]).config.fields.forEach(ff => {

            //             });
            //         } else if(c.type == "checkbox") {
            //             updateObj[c.name] = this.form.value[c.name] ? 1 : 0;
            //         } else if(c.type == "richtext") {
            //             updateObj[c.name] = JSON.parse(this.form.value[c.name]) != null ? JSON.stringify(JSON.parse(this.form.value[c.name]).ops) : null;
            //         } else {
            //             updateObj[c.name] = this.form.value[c.name];
            //         }
            //     }
            // };

            this.getUpdateObj(updateObj, this.fieldArray);

            this.fieldArray.forEach(c => {
                if(c.onSave) {
                    var rtn = c.onSave(updateObj, updateObj[c.name]);
                    if(rtn !== undefined) { updateObj[c.name] = rtn; }
                }
            });

            var submitResult;
            if(this.onSubmit != undefined) {
              submitResult = await this.onSubmit(this.apiService, updateObj);
                if(submitResult) {
                  if(!this.config.isNotReset) {
                    this.form.reset();
                  }
                }
                this.isLoading = false;
            } else {
                if((this.editObj == undefined || this.editObj == null || this.editObj.id == null) && !updateObj['id']) {
                    if(this.dataCreateByUrl) {
                      submitResult = await this.appService.dataPostByUrl(this.dataCreateByUrl, [updateObj]);
                    } else {
                      submitResult = await this.appService.dataPost(this.objClass, [updateObj]);
                    }
                } else {
                    if(this.dataUpdateByUrl) {
                      submitResult = await this.appService.dataPatchByUrl(this.dataUpdateByUrl, [updateObj['id'] ?? this.editObj.id], [updateObj]);
                    } else {
                      submitResult = await this.appService.dataPatch(this.objClass, [updateObj['id'] ?? this.editObj.id], [updateObj]);
                    }
                }
                this.isLoading = false;
                if(!this.config.isNotReset) {
                  this.form.reset();
                  this.fields.forEach(f => f.reset());
                }
            }

            this.submit.emit(submitResult);
            return true;
        } else {
            this.toastr.error('請填寫必要項目');
            return false;
        }
    }

    getUpdateObj(updateObj, fields: FieldConfig[]) {
        for(let cKey in fields) {
            var c = fields[cKey];
            if(this.form.value[c.name] !== undefined) {
                if(c.type == "tab") {
                    updateObj[c.name] = this.form.value[c.name];
                    var subFields = c.tab.find(t => t.key == this.form.value[c.name]).config.fields.reduce((fr, v) => fr.concat(v), []);
                    this.getUpdateObj(updateObj, subFields);
                    subFields.forEach(c => {
                        if(c.onSave) {
                          var rtn = c.onSave(updateObj, updateObj[c.name]);
                          if(rtn) { updateObj[c.name] = rtn; }
                        }
                    });
                } else if(c.type == "checkbox") {
                    updateObj[c.name] = this.form.value[c.name] ? 1 : 0;
                } else if(c.type == "richtext") {
                    updateObj[c.name] = JSON.parse(this.form.value[c.name]) != null ? JSON.stringify(JSON.parse(this.form.value[c.name]).ops) : null;
                } else {
                    updateObj[c.name] = this.form.value[c.name];
                }
            } else if(this.form.controls[c.name] !== undefined && this.form.controls[c.name].value !== undefined) {
              updateObj[c.name] = this.form.controls[c.name].value;
            }
        };
    }

    setDisabled(name: string, disable: boolean) {
        if(this.fields.find(f => f.config.name == name)) {
          var f = this.fields.find(f => f.config.name == name);
          if(f) { f.config.disabled = disable; }
          // console.log(this.fields.find(f => f.config.name == name).config.disabled);
        }

        if (this.form.controls[name]) {
            const method = disable ? 'disable': 'enable';
            this.form.controls[name][method]();
            return;
        }

        this.config.fields.map((fr) => {
            fr = fr.map(item => {
              if (item.name === name) {
                  item.disabled = disable;
              }
              return item;
          });
        });
    }

    setValue(name: string, value: any) {
        this.form.controls[name].setValue(value, {emitEvent: true});
    }

    public addField(f) {
        if(this.fields.indexOf(f) < 0) {
            this.fields.push(f);
        }
    }

    public slientFormFieldSetValue(name, value) {
        this.isEsculateFormUpdate = false;
        this.form.controls[name].setValue(value);
        this.isEsculateFormUpdate = true;
    }

    ngOnDestroy(): void {
      if(this.valueChangeSubscribe) { this.valueChangeSubscribe.unsubscribe(); }
      this.form.reset();
    }
}
