import {Component, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import { ButtonComponent } from '@components/button/button.component';
import { AppService } from '@services/app.service';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { exit } from 'process';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent {
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
    isDtInitialized: boolean = false;
    dataItems?: any[];
    @ViewChild(DataTableDirective) table: DataTableDirective | undefined;

    @Input() isCreatable: boolean = true;
    @Input() onAdd: () => void;
    @Input() cols: ColumnConfig[];
    get searchCols(): ColumnConfig[] { return this.cols.filter(c => c.searchable); }
    @Input() dataGetByUrl?: string;
    @Input() dataGetClass?: string;
    @Input() search: {key: string, value: string[]}[] = [];

    sortBy = "name";
    sortDir = "asc";
    searchType: string = "";
    searchTypeTitle: string = "";
    searchText: string = "";

    constructor(private appService: AppService,) {}

    ngOnInit(): void {
        var that = this;

        this.dtOptions = {
          pagingType: 'simple_numbers',
          pageLength: 25,
          autoWidth: true,
          lengthChange: false,
          searching: false,
          language: { url: '//cdn.datatables.net/plug-ins/1.11.5/i18n/zh-HANT.json' },
          serverSide: true,
          processing: true,
          columns: this.cols.map(c => c.setting),
        //   initComplete: () => {
        //     this.table.dtInstance.then((dtInstance: DataTables.Api) => {
        //       dtInstance.on('order', (e, a, sorts, sorting, d, f) => {
        //       })
        //     });
        //   },
          ajax: (dataTablesParameters: any, callback) => {
              // console.log(dataTablesParameters);
            var thisSearch: {key: string, value: string[]}[] = [];
            if(this.search) { this.search.forEach(s => thisSearch.push(s)) }
            if(this.searchText) {
                thisSearch.push({
                    key: this.searchType,
                    value: [this.searchText]
                });
            }
            if(this.dataGetClass != undefined) {
                this.appService.dataGet(
                    this.dataGetClass,
                    null,
                    thisSearch,
                    null,
                    dataTablesParameters.order.length > 0 && dataTablesParameters.order[0].dir == "asc" ? this.dtOptions.columns[dataTablesParameters.order[0].column].name : null,
                    dataTablesParameters.order.length > 0 && dataTablesParameters.order[0].dir == "desc" ? this.dtOptions.columns[dataTablesParameters.order[0].column].name : null,
                    this.dtOptions.pageLength,
                    dataTablesParameters.start
                ).then(async result => {
                    this.dataItems = result.payload.results;
                    callback({
                        recordsTotal: result.payload.properties.count,
                        recordsFiltered: result.payload.properties.count,
                    });
                });
            } else if(this.dataGetByUrl != undefined) {
                this.appService.dataGetByUrl(
                    this.dataGetByUrl,
                    null,
                    thisSearch,
                    null,
                    dataTablesParameters.order.length > 0 && dataTablesParameters.order[0].dir == "asc" ? this.dtOptions.columns[dataTablesParameters.order[0].column].name : null,
                    dataTablesParameters.order.length > 0 && dataTablesParameters.order[0].dir == "desc" ? this.dtOptions.columns[dataTablesParameters.order[0].column].name : null,
                    this.dtOptions.pageLength,
                    dataTablesParameters.start
                ).then(async result => {
                    this.dataItems = result.payload.results;
                    callback({
                        recordsTotal: result.payload.properties.count,
                        recordsFiltered: result.payload.properties.count,
                    });
                });
            }
          }
        };

        this.searchType = this.searchCols.length > 0 ? this.searchCols[0].setting.name : "";
        this.searchTypeTitle = this.searchCols.length > 0 ? this.searchCols[0].setting.title : "";

        // this.dtTrigger.next();
        // this.onTableRefresh();
    }

    ngAfterViewInit() {
        this.onTableRefresh();
    }

    async onTableRefresh()  {
        if(!this.isDtInitialized) {
            this.isDtInitialized = true;
        } else {
            (await this.table.dtInstance).destroy();
        }
        this.dtTrigger.next(null);
    }

    onSearchDropdownPick(field: string) {
        this.searchType = field;
        this.searchTypeTitle = this.searchCols.find(s => s.setting.name == field).setting.title;
    }

    onSearchButtonPress(search: string) {
      this.searchText = search;
      this.onTableRefresh();
    }

    onAddRecord() {
        if(this.onAdd != undefined) { this.onAdd(); }
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }
}

export class ColumnConfig {
    public setting: DataTables.ColumnSettings;
    public buttons?: any[];
    public searchable?: boolean = false;
}
