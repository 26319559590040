import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  isDtInitialized: boolean = false;
  @ViewChild('table') table: TableComponent;
  @ViewChild('formModal') public formModal: TemplateRef<any>;
  onAdd = (): void => {
    this.onCreate(this.formModal);
  }
  dataGetClass: string = "news";
  cols: ColumnConfig[];
  search: {key: string, value: string[]}[] = [];

  objClass: string = "news";
  formDef: FieldConfig[];
  editObj;

  sortBy = "name";
  sortDir = "asc";
  searchType: string = "客戶名稱";
  searchText: string = "";

  modal: NgbModalRef;
  active = 1;
  public isLoading = false;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.cols = [
      { setting: { name: 'title', title: '標題' }, searchable: true, },
      { setting: { name: 'tags', title: '標簽', orderable: false, searchable: true, }, },
      { setting: { name: 'thumbnail', title: '細圖', render: function(data) {
        return data ? '<img style="width: 100px;" src="' + data + '" />' : '';
      } }, searchable: true, },
      { setting: { name: 'banner', title: '主圖片', render: function(data) {
        var dom = new DOMParser().parseFromString(data, "text/html");
        return data ? '<img style="width: 100px;" src="' + data + '" />' : '';
      } }, searchable: true, },
      { setting: { name: 'status', title: '狀態', render: function(data) {
          switch(data) {
            case "normal":
              return "正常";
            case "disable":
              return "停用";
          }
          return "正常";
        } }, searchable: true, },
      { setting: { title: '動作', orderable: false }, buttons: [
        {
          name: "修改",
          class: "btn-primary pull-right",
          onClick: (dataItem) => this.onEditAccount(this.formModal, dataItem)
        }
      ] },
    ];

    this.search = [];

    this.formDef = [
      {
        name: "title",
        label: "標題",
        type: "text"
      },
      {
        name: "tags",
        label: "標簽",
        type: "text",
      },
      {
        name: "thumbnail",
        label: "細圖",
        type: "image",
      },
      {
        name: "banner",
        label: "主圖片",
        type: "image",
      },
      {
        name: "content_json",
        label: "內容",
        type: "richtext",
        onSave: (objRef, input) => {
          if(input) {
            var converter = new QuillDeltaToHtmlConverter(JSON.parse(input), {});
            objRef.content = converter.convert();
          }
        }
      },
      {
        name: "status",
        label: "狀態",
        type: "select",
        select: [
          {key: "normal", text: "正常"},
          {key: "disable", text: "停用"},
        ]
      },
    ];
  }

  onCreate(formModal) {
    this.editObj = undefined;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onEditAccount(formModal, dataItem) {
    this.editObj = dataItem;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onSubmitted(result) {
    this.table.onTableRefresh();
    this.modal.dismiss('Saved');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
