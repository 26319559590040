import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { ChangePage, SetUiMode, UpdateSelectedNewsId, UpdateSelectedPlanId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { AppState } from '@/store/state';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { ApiService } from '@services/api.service';
import { FormConfig } from '@components/formfields/form-config.interface';
import { async } from '@angular/core/testing';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  loginFormConfig: FormConfig;

  socialAuthSub?: Subscription;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      public router: Router,
      private navigation: NavigationService,
      private socialAuthService: SocialAuthService
  ) {}

  ngOnInit(): void {
    this.loginFormConfig = {
      submitText: "登入",
      fields: [
        [{
          name: "name",
          label: "電郵/用戶名",
          type: "text"
        }],
        [{
          name: "password",
          label: "密碼",
          type: "password"
        }],
      ]
    };
    this.store.dispatch(new ChangePage(null));

    this.socialAuthSub = this.socialAuthService.authState.subscribe((user) => {
      if(user) {
        console.log(user);
        this.appService.loginByGoogle(user.id, user.idToken);
      }
    });
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    this.socialAuthSub?.unsubscribe();
  }

  onSubmit = async (apiService: ApiService, objRef) => {
    var result = await this.appService.loginByAuth({email: objRef.name, password: objRef.password});
  }

  onFacebookLogin = async () => {
    var result = await this.appService.loginByFacebook();
  }

  onAppleLogin = async () => {
    var result = await this.appService.loginByApple();
  }

  onForgetPassword = () => {
    this.router.navigate(['forgetpassword']);
  }

  onRegister = () => {
    this.router.navigate(['register']);
  }

  onSubmitted($event) {

  }
}
