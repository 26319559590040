import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {}

	async loginByAuth(email: string, password: string, method: string = "local", type: string = "customer"): Promise<LoginResult> {
        var postBody = {
            type: type,
            method: method,
            platform: "web",
            username: email,
            password: password,
        };
		return this.http.post<LoginResult>("https://devless.digitsolu.com/custapi/v1/service/TeleBro/User/login", postBody, {
            headers: new HttpHeaders({
                "Devless-token": "8bd697168b681473e1bc0c8ff6c58ef4"
            })
        }).toPromise();
	}

	async register(username: string, email: string, password: string, mobile: string, name: string, type: string = "customer"): Promise<LoginResult> {
        var postBody: any = {
            type: type,
            platform: "web",
            username: username,
            email: email,
            password: password,
        };
        if(mobile) { postBody.mobile = mobile; }
        if(name) { postBody.name = name; }
		return this.http.post<LoginResult>("https://devless.digitsolu.com/custapi/v1/service/TeleBro/User/register", postBody, {
            headers: new HttpHeaders({
                "Devless-token": "8bd697168b681473e1bc0c8ff6c58ef4"
            })
        }).toPromise();
	}

	async loginByToken(token: string): Promise<LoginResult> {
        var postBody = {
            token: token,
            method: "token"
        };
		return this.http.post<LoginResult>("https://devless.digitsolu.com/custapi/v1/service/TeleBro/User/login", postBody, {
            headers: new HttpHeaders({
                "Devless-token": "8bd697168b681473e1bc0c8ff6c58ef4"
            })
        }).toPromise();
	}

	async loginBySocial(method: string = "local", type: string = "customer", data): Promise<LoginResult> {
        var postBody = {
            type: type,
            method: method,
            platform: "web",
            ...data
        };
		return this.http.post<LoginResult>("https://devless.digitsolu.com/custapi/v1/service/TeleBro/User/login", postBody, {
            headers: new HttpHeaders({
                "Devless-token": "8bd697168b681473e1bc0c8ff6c58ef4"
            })
        }).toPromise();
	}

	async userUpdate(username: string, email: string, mobile: string, name: string, token: string): Promise<LoginResult> {
        var postBody: any = {
            token: token
        };
        if(username) { postBody.username = username; }
        if(email) { postBody.email = email; }
        if(mobile) { postBody.mobile = mobile; }
        if(name) { postBody.name = name; }
		return this.http.post<LoginResult>("https://devless.digitsolu.com/custapi/v1/service/TeleBro/User/infoupdate", postBody, {
            headers: new HttpHeaders({
                "Devless-token": "8bd697168b681473e1bc0c8ff6c58ef4"
            })
        }).toPromise();
	}

  async requestResetPassword(email: string): Promise<LoginResult> {
    var postBody: any = {
      email: email
    };
    return this.http.post<LoginResult>("https://devless.digitsolu.com/custapi/v1/service/TeleBro/User/requestresetpassword", postBody, {
      headers: new HttpHeaders({
        "Devless-token": "8bd697168b681473e1bc0c8ff6c58ef4"
      })
    }).toPromise();
  }

  async resetPassword(email: string, passcode: string, password: string): Promise<LoginResult> {
    var postBody: any = {
      email: email,
      passcode: passcode,
      password: password
    };
    return this.http.post<LoginResult>("https://devless.digitsolu.com/custapi/v1/service/TeleBro/User/resetpassword", postBody, {
      headers: new HttpHeaders({
        "Devless-token": "8bd697168b681473e1bc0c8ff6c58ef4"
      })
    }).toPromise();
  }

    async dataGet(table: string, where: {key: string, value: string} = null, search: {key: string, value: string[]}[] = null, notEqual: {key: string, value: string} = null, asc: string = null, desc: string = null, size: number = null, offset: number = null, related: string = "*"): Promise<GetResult> {
        var getParam: HttpParams = new HttpParams();
        getParam = getParam.append("table", table);
        if(where != null) {
            getParam = getParam.append("where", where.key + "," + where.value);
        }
        if(search != null) {
            search.forEach(s => {
                getParam = getParam.append("search", s.key + "," + s.value.join(' '));
            })
        }
        if(notEqual != null) {
            getParam = getParam.append("notEqual", notEqual.key + "," + notEqual.value);
        }
        if(asc != null) {
            getParam = getParam.append("asc", asc);
        }
        if(desc != null) {
            getParam = getParam.append("desc", desc);
        }
        if(size != null) {
            getParam = getParam.append("size", size);
        }
        if(offset != null) {
            getParam = getParam.append("offset", offset);
        }
        getParam = getParam.append("related", related);
		return this.http.get<GetResult>("https://devless.digitsolu.com/api/v1/service/TeleBro/db", {
            headers: this.getCommonHttpHeaders(),
            params: getParam
        }).toPromise();
    }

    async dataGetByUrl(url: string, where: {key: string, value: string} = null, search: {key: string, value: string[]}[] = null, notEqual: {key: string, value: string} = null, asc: string = null, desc: string = null, size: number = null, offset: number = null, related: string = "*"): Promise<GetResult> {
        var getParam: HttpParams = new HttpParams();
        if(where != null) {
            getParam = getParam.append("where", where.key + "," + where.value);
        }
        if(search != null) {
            search.forEach(s => {
                getParam = getParam.append("search", s.key + "," + s.value.join(' '));
            })
        }
        if(notEqual != null) {
            getParam = getParam.append("notEqual", notEqual.key + "," + notEqual.value);
        }
        if(asc != null) {
            getParam = getParam.append("asc", asc);
        }
        if(desc != null) {
            getParam = getParam.append("desc", desc);
        }
        if(size != null) {
            getParam = getParam.append("size", size);
        }
        if(offset != null) {
            getParam = getParam.append("offset", offset);
        }
        getParam = getParam.append("related", related);
		return this.http.get<GetResult>("https://devless.digitsolu.com/" + url, {
      headers: this.getCommonHttpHeaders(),
            params: getParam
        }).toPromise();
    }

    async dataPost(table: string, data: any[]): Promise<PostResult> {
        var getParam: HttpParams = new HttpParams();
        getParam = getParam.append("table", table);
		return this.http.post<PostResult>("https://devless.digitsolu.com/api/v1/service/TeleBro/db", {
            "resource": [{
                "name": table,
                "field": data
            }]
        }, {
          headers: this.getCommonHttpHeaders(),
            params: getParam
        }).toPromise();
    }

    async dataPostByUrl(url: string, data: any[]): Promise<PostResult> {
        var getParam: HttpParams = new HttpParams();
		return this.http.post<PostResult>("https://devless.digitsolu.com/" + url, {
            data: data
        }, {
          headers: this.getCommonHttpHeaders(),
            params: getParam
        }).toPromise();
    }

    async dataPatch(table: string, ids: number[], data: any[]): Promise<PostResult> {
        var getParam: HttpParams = new HttpParams();
        getParam = getParam.append("table", table);
        var params = [];
        for(var i=0;i<ids.length;i++) {
            params.push({
                "where": "id," + ids[i],
                "data": [data[i]],
            });
        }
		return this.http.patch<PostResult>("https://devless.digitsolu.com/api/v1/service/TeleBro/db", {
            "resource": [{
                "name": table,
                "params": params
            }]
        }, {
          headers: this.getCommonHttpHeaders(),
            params: getParam
        }).toPromise();
    }

    async dataPatchByUrl(url: string, ids: number[], data: any[]): Promise<PostResult> {
        var getParam: HttpParams = new HttpParams();
        var params = [];
        for(var i=0;i<ids.length;i++) {
            params.push({
                "where": "id," + ids[i],
                "data": [data[i]],
            });
        }
		return this.http.patch<PostResult>("https://devless.digitsolu.com/" + url, {
            data: params
        }, {
          headers: this.getCommonHttpHeaders(),
            params: getParam
        }).toPromise();
    }

    async dataDelete(table: string, ids: number[]): Promise<PostResult> {
        var getParam: HttpParams = new HttpParams();
        getParam = getParam.append("table", table);
        var params = [];
        for(var i=0;i<ids.length;i++) {
            params.push({
                "where": "id," + ids[i],
                "delete": true,
            });
        }
		return this.http.delete<PostResult>("https://devless.digitsolu.com/api/v1/service/TeleBro/db", {
          headers: this.getCommonHttpHeaders(),
            params: getParam,
            body: {
              "resource": [{
                  "name": table,
                  "params": params
              }]
          },
        }).toPromise();
    }

    async uploadFile(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);
		return this.http.post<PostResult>("https://devless.digitsolu.com/file/v1/TeleBro/" + file.name, formData, {
      headers: this.getCommonHttpHeaders(),
        }).pipe(
            map(p => {
                if(p.payload.entry) {
                    return "https://devless.digitsolu.com/file/v1/TeleBro/" + p.payload.entry.name;
                }
                throw new Error(p.message);
            })
        ).toPromise();
    }

    getCommonHttpHeaders(): HttpHeaders {
      var headers = new HttpHeaders({
        "Devless-token": "8bd697168b681473e1bc0c8ff6c58ef4",
        // "token": localStorage.getItem('token')
      });
      // var token = localStorage.getItem('token');
      // if(token) {
      //   headers = headers.append('token2', token);
      // }
      return headers;
    }
}

interface LoginResult {
    status: boolean;
    token: string;
    message: string;
    user;
}
interface GetResult {
    status_code: number;
    message: string;
    payload: GetResultPayload;
}
interface GetResultPayload {
    properties: GetResultPayloadProperties;
    results: any[];
}
interface GetResultPayloadProperties {
    count: number;
    current_count: number;
}
interface PostResult {
    status_code: number;
    message: string;
    payload: PostResultPayload;
}
interface PostResultPayload {
    entry_id: number;
    entry: any;
}
