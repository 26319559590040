<!-- Content Header (Page header) -->
<!-- <section class="content-header pb-0">
    <div class="container-fluid">
        <div class="row mb-2">
                <img src="/assets/img/icon-white.png" />
        </div>
        <div class="row">
          <h4>最新消息</h4>
        </div>
    </div>
</section> -->

<!-- Content Header (Page header) -->
<!-- <section class="pb-0 function-btns">
    <div class="row m-0">
      <div class="col pt-2 pb-2">
        <div>
        <div><img src="assets/img/botbtn-selected-news.png" /></div>
        <div style="color: rgba(8,111,215,1)">最新消息</div>
      </div>
      </div>
      <div class="col pt-2 pb-2" (click)="onViewChange('fixedwired')"><div><img src="assets/img/botbtn-unselect-homelan.png" /></div>
      <div>固網寛頻</div></div>
      <div class="col pt-2 pb-2" (click)="onViewChange('fixedcellular')"><div><img src="assets/img/botbtn-unselect-home.png" /></div>
      <div>家居5G寬頻</div></div>
      <div class="col pt-2 pb-2" (click)="onViewChange('mobile')"><div><img src="assets/img/botbtn-unselect-mobile.png" /></div>
      <div>手機數據</div></div>
      <div class="col pt-2 pb-2" (click)="onViewChange('tv')"><div><img src="assets/img/botbtn-unselect-tv.png" /></div>
      <div>收費電視</div></div>
    </div>
</section> -->

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
      <div infinite-scroll
        class="infinite-scroll"
        [scrollWindow]="false"
        [infiniteScrollDistance]="distance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onScroll()">
        <a *ngFor="let n of news" (click)="onNewsItemSelect($event, n)" [href]="'/news/' + n.id">
          <div class="news-item row mt-2" style="margin: 0">
            <div class="col-4 thumbnail" style="background-image: url('{{ n.thumbnail }}')">
            </div>
            <div class="col-8">
              <div class="title">{{ n.title }}</div>
              <div class="tag">{{ n.tags }}</div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
