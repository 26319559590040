import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NumpadComponent } from '@components/numpad/numpad.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-numberfield',
    templateUrl: './numberfield.component.html',
    styleUrls: ['./numberfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormNumberFieldComponent extends Field {
    currentObj: object;
    editObj: object;
    config: FieldConfig;
    group: UntypedFormGroup;

    get value(): number {
      return this.form.form.controls[this.config.name].value;
    }

    set value(v: number) {
      this.form.form.controls[this.config.name].setValue(v);
    }

    modal: NgbModalRef;
    @ViewChild('numpadModal') public numpadModal: TemplateRef<any>;
    @ViewChild('numpad') public numpad: NumpadComponent;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    constructor(
      private modalService: NgbModal
    ){ super(); }
    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);
    }

    launchNumpad() {
      this.modal = this.modalService.open(this.numpadModal, { size: 'md', windowClass: 'modal-right' });
    }

    dismissNumpad = (): void => {
      if(this.modal) this.modal.dismiss();
    }
}
