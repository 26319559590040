<div class="form-group row" [formGroup]="group">
    <label class="col-sm-2 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <div class="col-sm-10">
        <div class="input-group-prepend" ngbDropdown>
            <button type="button" class="btn border rounded" id="currencyDropdown" ngbDropdownToggle [disabled]="config.disabled">
              {{ optionName(this.form.form.controls[this.config.name].value) }}
            </button>
            <div ngbDropdownMenu aria-labelledby="currencyDropdown">
                <button ngbDropdownItem *ngFor="let selectoption of config.select" (click)="selecting($event, selectoption)">
                    {{ selectoption.text }}
                </button>
            </div>
        </div>
    </div>
    <input type="hidden" [formControlName]="config.name" #hidden />
</div>
