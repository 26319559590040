import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NumpadComponent } from '@components/numpad/numpad.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import { Subscription } from 'rxjs';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-textfield',
    templateUrl: './textfield.component.html',
    styleUrls: ['./textfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormTextFieldComponent extends Field {
    currentObj: object;
    editObj: object;
    config: FieldConfig;
    group: UntypedFormGroup;

    modal: NgbModalRef;
    @ViewChild('numpadModal') public numpadModal: TemplateRef<any>;
    @ViewChild('numpad') public numpad: NumpadComponent;

    valueChangeSubscribe: Subscription;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // constructor(){}
    // config: FieldConfig;
    constructor(
      private modalService: NgbModal
    ){ super(); }

    ngOnInit() {
        this.form.addField(this);
        if(this.config.onValueChanges) {
          this.valueChangeSubscribe = this.form.form.controls[this.config.name].valueChanges.subscribe((v) => this.config.onValueChanges(v, this.form, this));
        }
    }

    isConvertToGBButton(): boolean {
      return this.config.options && this.config.options.indexOf('chinese') >= 0;
    }

    dismissNumpad = (): void => {
      if(this.modal) this.modal.dismiss();
    }

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // config: FieldConfig;

    launchNumpad() {
      this.modal = this.modalService.open(this.numpadModal, { size: 'md', windowClass: 'modal-right' });
    }

    get value(): string {
      return this.form.form.controls[this.config.name].value;
    }

    set value(v: string) {
      this.form.form.controls[this.config.name].setValue(v);
    }

    onBlur($event) {
      if(this.config.onBlur) {
        this.config.onBlur(this.form.form.controls[this.config.name].value, this.form);
      }
    }

    ngOnDestroy() {
      if(this.valueChangeSubscribe) { this.valueChangeSubscribe.unsubscribe(); }
    }
}
