<div class="form-group row" [formGroup]="group" *ngIf="config.isShow == undefined || config.isShow(form.currentObj)">
    <label class="col-sm-2 col-form-label">{{ config.label }}<span *ngIf="isRequired()" class="text-danger"> *</span></label>
    <div class="col-sm-10">
      <div class="input-group">
        <input class="form-control"
            type="number"
            [attr.placeholder]="config.placeholder"
            [formControlName]="config.name">
        <div class="input-group-append" *ngIf="config.isNumpad">
          <button type="button" class="btn btn-secondary" id="launchNumpad" (click)="launchNumpad()">
            <i class="fas fa-calculator"></i>
          </button>
        </div>
      </div>
      <label class="remark" *ngIf="config.remark">{{ config.remark }}</label>
    </div>
</div>

<ng-template #numpadModal let-modal>
  <div class="modal-header">
      <span class="modal-title">數字鍵盤</span>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body pos">
    <app-numpad [(value)]="value" [onExit]="dismissNumpad" #numpad></app-numpad>
  </div>
</ng-template>
