import {Component, Input} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@components/form/form.component';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-buttonselectfield',
    templateUrl: './buttonselectfield.component.html',
    styleUrls: ['./buttonselectfield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormButtonSelectFieldComponent extends Field {
    form: FormComponent;
    config: FieldConfig;
    group: UntypedFormGroup;

    othervalue: string;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);

        if(this.config.selectBuilder != null) {
            this.config.select = this.config.selectBuilder(this.editObj);
        }
        if(this.config.valueBuilder != null) {
            this.config.value = this.config.valueBuilder(this.editObj);
        }
    }

    ngAfterViewInit() {
        this.group.get(this.config.name).setValue(this.config.value);
    }

    onSelect(key) {
      if(this.config.disabled) { return; }
      this.form.form.controls[this.config.name].setValue(key);
      this.othervalue = "";
      if(this.config.onValueChanges) {
        this.config.onValueChanges(key, this.form, this);
      }
    }

    onOtherChange($event) {
      this.form.form.controls[this.config.name].setValue(this.othervalue);
    }

    isSelected(key) : boolean {
      if(key == 'other') {
        return this.form.form.controls[this.config.name].value && !this.config.select.find(s => s.key == this.form.form.controls[this.config.name].value);
      }
      return key == this.form.form.controls[this.config.name].value;
    }

    isAllowOther(): boolean {
      return this.config.options && this.config.options.indexOf('other') >= 0;
    }
}
