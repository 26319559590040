import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
// import {Gatekeeper} from 'gatekeeper-client-sdk';
import { ApiService } from './api.service';
import { Store } from '@ngrx/store';
import { StateState } from '@/store/state/state';
import { UpdateAds, UpdateBanners, UpdateConfig, UpdateProviders } from '@/store/state/actions';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { isPlatformBrowser } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    public tags: Tag[] = [];
    public providers: ServiceProvider[] = [];
    public configs: SiteConfig[] = [];
    public banners: any[] = [];
    public ads: any[] = [];

    get isBrowserOnly(): boolean {
       return isPlatformBrowser(this.platformId);
    }

    constructor(private router: Router, private toastr: ToastrService, private apiService: ApiService, private store: Store<StateState>, private authService: SocialAuthService,
      @Inject(PLATFORM_ID) private platformId: string, private jwtHelper: JwtHelperService) {
        this.configGet();
        this.getProfile();
    }

    async loginByAuth({email, password}) {
        try {
            // const token = await Gatekeeper.loginByAuth(email, password);
            const result = await this.apiService.loginByAuth(email, password);
            if(!result.status) {
                throw new Error("用戶名稱或密碼不正確");
            }
            localStorage.setItem('token', result.token);
            this.user = result.user;
            // await this.getProfile();
            this.router.navigate(['/member']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({username, email, password, mobile, name}) {
        try {
          const result = await this.apiService.register(username, email, password, mobile, name);
          if(!result.status) {
              throw new Error(result.message);
          }

          localStorage.setItem('token', result.token);
          this.user = result.user;
          // await this.getProfile();
          this.toastr.success('注冊成功！');
          this.router.navigate(['/member']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle(userId: string, idToken: string) {
        try {
            const result = await this.apiService.loginBySocial('google', 'customer', {
              "user_id": userId,
              "id_token": idToken
            });
            localStorage.setItem('token', result.token);
            this.user = result.user;
            // await this.getProfile();
            this.router.navigate(['/member']);

        } catch (error) {
          console.log(error);
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
          const user = await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
          if(user.authToken) {
            const result = await this.apiService.loginBySocial('facebook', 'customer', {
              "user_id": user.id,
              "id_token": user.authToken
            });
            localStorage.setItem('token', result.token);
            this.user = result.user;
            // await this.getProfile();
            this.router.navigate(['/member']);
          }

        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByApple() {
      const CLIENT_ID = "com.kinandfung.telebro.web"
      const REDIRECT_API_URL = "https://devless.digitsolu.com/custapi/v1/service/TeleBro/User/login?method=appleweb&Devless-token=8bd697168b681473e1bc0c8ff6c58ef4"
      window.open(
          `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name email&response_mode=form_post`,
          '_blank'
      );

      window.addEventListener('message', async event => {
          const decodedToken = this.jwtHelper.decodeToken(event.data.id_token);
          let requestData = {}
          if (event.data.user) {
              const userName = JSON.parse(event.data.user);
              requestData = {
                  "email": decodedToken.email,
                  "name": `${userName.name.firstName} ${userName.name.lastName}`,
                  "socialId": decodedToken.sub,
              };
          } else {
              requestData = {
                  "email": decodedToken.email,
                  "socialId": decodedToken.sub,
              };
          }
          console.log(`User Data : ${requestData}`);
          // do your next stuff here

          const result = await this.apiService.loginBySocial('applew', 'customer', {
            "user_id": event.data.code,
            "id_token": event.data.id_token
          });
          localStorage.setItem('token', result.token);
          this.user = result.user;
          // await this.getProfile();
          this.router.navigate(['/member']);
      });
    }

    async getProfile() {
      if(localStorage.getItem('token')) {
        try {
            const result = await this.apiService.loginByToken(localStorage.getItem('token'));
            if(!result.status) {
                throw new Error("用戶登入已到期");
            }
            this.user = result.user;
        } catch (error) {
            this.logout();
            throw error;
        }
      }
    }

    async updateProfile({username, email, mobile, name}) {
        try {
            const result = await this.apiService.userUpdate(username, email, mobile, name, localStorage.getItem('token'));
            if(!result.status) {
                throw new Error(result.message);
            }
            this.user = result.user;
            this.toastr.success('帳戶更新成功')
        } catch (error) {
            // this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        // localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }

    async requestResetPassword(email: string) {
      try {
        const result = await this.apiService.requestResetPassword(email);
        if(!result.status) {
          this.toastr.error(result.message);
          return;
        }
        this.toastr.success('重設密碼電郵已發送');
      } catch (error) {
        throw error;
      }
    }

    async resetPassword(email: string, passcode: string, password: string): Promise<boolean> {
      try {
        const result = await this.apiService.resetPassword(email, passcode, password);
        if(!result.status) {
          this.toastr.error(result.message);
          return;
        }
        this.toastr.success('密碼已重設，請用新密碼登入');
        return true;
      } catch (error) {
        throw error;
      }
    }

    async dataGet(table: string, where: {key: string, value: string} = null, search: {key: string, value: string[]}[] = null, notEqual: {key: string, value: string} = null, asc: string = null, desc: string = null, size: number = null, offset: number = null, related: string = "*") {
        try {
            const result = await this.apiService.dataGet(table, where, search, notEqual, asc, desc, size, offset, related);
            if(result.status_code != 625) {
                throw new Error(result.message);
            }
            return result;
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async dataGetByUrl(url: string, where: {key: string, value: string} = null, search: {key: string, value: string[]}[] = null, notEqual: {key: string, value: string} = null, asc: string = null, desc: string = null, size: number = null, offset: number = null, related: string = "*") {
        try {
            const result = await this.apiService.dataGetByUrl(url, where, search, notEqual, asc, desc, size, offset, related);
            if(result.status_code != 625) {
                throw new Error(result.message);
            }
            return result;
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async dataPost(table: string, data: any[]) {
        try {
            const result = await this.apiService.dataPost(table, data);
            if(result.status_code != 609) {
                throw new Error(result.message);
            }
            return result;
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async dataPostByUrl(url: string, data: any[]) {
        try {
            const result = await this.apiService.dataPostByUrl(url, data);
            if(result.status_code != 609) {
                throw new Error(result.message);
            }
            return result;
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async dataPatch(table: string, ids: any[], data: any[]) {
        try {
            const result = await this.apiService.dataPatch(table, ids, data);
            if(result.status_code != 619 && result.status_code != 629 /* 629 maybe no data change */) {
                throw new Error(result.message);
            }
            return result;
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async dataPatchByUrl(url: string, ids: any[], data: any[]) {
        try {
            const result = await this.apiService.dataPatchByUrl(url, ids, data);
            if(result.status_code != 619 && result.status_code != 629 /* 629 maybe no data change */) {
                throw new Error(result.message);
            }
            return result;
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async dataDelete(table: string, ids: any[]) {
        try {
            const result = await this.apiService.dataDelete(table, ids);
            if(result.status_code != 636) {
                throw new Error(result.message);
            }
            return result;
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async uploadFile(file: File): Promise<string> {
        try {
            const result = await this.apiService.uploadFile(file);
            return result;
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async configGet() {
        this.tags = (await this.dataGet("tags")).payload.results;
        this.providers = (await this.dataGet("providers")).payload.results;
        if(this.providers && this.providers.length > 0) {
          this.store.dispatch(new UpdateProviders(this.providers));
        }

        if(this.isBrowserOnly) {
          this.configs = (await this.dataGet("configs")).payload.results;
          if(this.configs && this.configs.length > 0) {
            this.store.dispatch(new UpdateConfig(this.configs[0]));
          }
          this.banners = (await this.dataGet("banners", {key: 'status', value: 'normal'}, null, null, 'rand()')).payload.results;
          if(this.banners && this.banners.length > 0) {
            this.store.dispatch(new UpdateBanners(this.banners));
          }
          this.ads = (await this.dataGet("ads", {key: 'status', value: 'normal'}, null, null, 'rand()')).payload.results;
          if(this.ads && this.ads.length > 0) {
            this.store.dispatch(new UpdateAds(this.ads));
          }
        }
    }

    getTagById(id: number) : Tag {
        try {
            return this.tags.find(c => c.id == id);
        } catch(e) { }
        return null;
    }

    getTagsByPlanType(type: string) : Tag[] {
        try {
            return this.tags.filter(c => c.type == type);
        } catch(e) { }
        return null;
    }

    getProvidersByPlanType(type: string) : ServiceProvider[] {
        try {
            return this.providers.filter(c => c.category == type);
        } catch(e) { }
        return null;
    }
}

export interface Tag {
    id: number;
    type: string;
    name: string;
}

export interface ServiceProvider {
    id: number;
    category: string;
    name: string;
    color_code: string;
}

export interface SiteConfig {
    id: number;
    fixedwiredbg: string;
    fixedcellularbg: string;
    mobilebg: string;
    tvbg: string;
    viewcount: number;
    salesicon: string;
}
