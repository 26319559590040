import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  isDtInitialized: boolean = false;
  @ViewChild(TableComponent) table: TableComponent;

  sortBy = "name";
  sortDir = "asc";
  searchType: string = "戶口名稱";
  searchText: string = "";

  @ViewChild('formModal') public formModal: TemplateRef<any>;
  public form: UntypedFormGroup;
  formItemId: number = null;
  modal: NgbModalRef;
  public isLoading = false;
  onAddAccount = (): void => {
    this.onCreateAccount(this.formModal);
  }
  cols: ColumnConfig[];

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private resolver: ComponentFactoryResolver,
      private injector: Injector
  ) {}

  ngOnInit(): void {
    var that = this; 

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      account_number: new UntypedFormControl(null),
      address: new UntypedFormControl(null),
      remarks: new UntypedFormControl(null),
    });

    this.cols = [
      { setting: { name: 'name', title: '戶口名稱' }, searchable: true, },
      { setting: { name: 'currencyNames', title: '貨幣種類', orderable: false,  }, },
      { setting: { name: 'account_number', title: '戶口號碼' }, searchable: true, },
      { setting: { name: 'balanceHkd', title: '折合結餘 (HKD)', orderable: false,  }, },
      { setting: { title: '動作', orderable: false }, buttons: [
        {
          name: "修改",
          class: "btn-primary pull-right",
          onClick: (dataItem) => this.onEditAccount(this.formModal, dataItem)
        },
        {
          name: "報表",
          class: "btn-success pull-right"
        }
      ] },
    ];

    // { data: 'name' },
    //     { orderable: false, data: 'currencyNames' },
    //     { data: 'account_number' },
    //     { orderable: false, data: 'balanceHkd' },
    //     { 
    //       orderable: false,
    //       render: function(data, type, full, meta) {
    //         return '<app-button class="btn btn-mini btn-primary pull-right">修改</app-button> <app-button class="btn btn-mini btn-success pull-right">報表</app-button>'; 
    //       }
    //     }
  }

  ngAfterViewInit() {
  }

  onCreateAccount(formModal) {
    this.formItemId = null;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  onEditAccount(formModal, dataItem) {
    this.form.reset({
      "name": dataItem.name,
      "account_number": dataItem.account_number,
      "address": dataItem.address,
      "remarks": dataItem.remarks,
    });
    this.formItemId = dataItem.id;
    this.modal = this.modalService.open(formModal, { size: 'lg' });
  }

  async formSubmit() {
      if (this.form.valid) {
          this.isLoading = true;
          if(this.formItemId == null) {
            await this.appService.dataPost("accounts", [{
              name: this.form.value["name"],
              account_number: this.form.value["account_number"],
              address: this.form.value["address"],
              remarks: this.form.value["remarks"],
            }]);
          } else {
            await this.appService.dataPatch("accounts", [this.formItemId], [{
              name: this.form.value["name"],
              account_number: this.form.value["account_number"],
              address: this.form.value["address"],
              remarks: this.form.value["remarks"],
            }]);
          }
          this.isLoading = false;
          this.table.onTableRefresh();
          this.form.reset();
          this.modal.dismiss('Saved');
      } else {
          this.toastr.error('請填寫必要項目');
      }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}