import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-numpad',
    templateUrl: './numpad.component.html',
    styleUrls: ['./numpad.component.scss']
})
export class NumpadComponent {
  @Input() public mode: string = 'number';
  @Input() public value: string = '0';
  @Input() public hideValue: boolean = false;
  @Input() public onExit: () => void = null;
  @Output() valueChange = new EventEmitter<string>();
  public valueStr: string = "0";

  constructor() {}

  ngOnInit() {
    if(!this.value) { this.value = '0'; }
    this.valueStr = this.value.toString();
  }

  onClick(digit: number) {
    if(this.valueStr === "0") {
      if(digit === 0) {
        return;
      } else {
        this.valueStr = "";
      }
    }
    this.valueStr += digit.toString();
    if(this.mode == 'number') {
      this.value = parseFloat(this.valueStr).toString();
    } else {
      this.value = this.valueStr;
    }
    this.valueChange.emit(this.value);
  }

  onDotClick() {
    if(this.valueStr.indexOf('.') <= -1) {
      this.valueStr += '.';
    }
  }

  onSpaceClick() {
    this.valueStr += ' ';
    this.value = this.valueStr;
    this.valueChange.emit(this.value);
  }

  onClearClick() {
    if(this.mode == "number") {
      this.valueStr = '0';
      this.value = '0';
    } else {
      this.valueStr = '';
      this.value = '';
    }
    this.valueChange.emit(this.value);
  }

  onBackspaceClick() {
    this.valueStr = this.valueStr.slice(0, -1);
    this.value = this.valueStr;
    this.valueChange.emit(this.value);
  }

  onConfirmClick() {
    this.onExit();
  }
}
