import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { FieldConfig } from '../field-config.interface';
import { Field } from '../field.interface';

@Component({
    selector: 'app-form-datetimefield',
    templateUrl: './datetimefield.component.html',
    styleUrls: ['./datetimefield.component.scss'],
    // host: {'class': 'form-group row'}
})
export class FormDateTimeFieldComponent extends Field {
    config: FieldConfig;
    group: UntypedFormGroup;

    // @Input() title: string;
    // @Input() name: string;
    // @Input() placeholder: string;
    // @Input() group: FormGroup;

    // constructor(){}
    // config: FieldConfig;

    ngOnInit() {
        this.form.addField(this);
    }

    ngAfterViewInit() {

    }

    onChange($event) {
        this.group.controls[this.config.name].setValue($event.year + '-' + $event.month.toString().padStart(2, '0') + '-' + $event.day.toString().padStart(2, '0'));
    }

    reset() {
        // this.datebox.nativeElement.value = "";
    }
}
