<a 
    *ngIf="menuItem.children == undefined"
    (click)="handleMainMenuAction()"
    class="nav-link"
    [ngClass]="{active: isMainActive || isOneOfChildrenActive}"
>
    <i class="nav-icon fas" [class]="menuItem.icon == undefined ? 'fa-tachometer-alt' : menuItem.icon"></i>
    <p>{{ menuItem.name }}</p>
</a>

<p class="nav-link" *ngIf="menuItem.children != undefined">{{ menuItem.name }}</p>
<ul class="nav nav-treeview" *ngFor="let item of menuItem.children">
    <li class="nav-item">
        <a
            [routerLink]="item.path"
            [routerLinkActive]="'active'"
            class="nav-link"
        >
            <i class="fas nav-icon" [class]="item.icon == undefined ? 'fa-circle' : item.icon"></i>
            <p>{{ item.name }}</p>
        </a>
    </li>
</ul>

