<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>戶口設定</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-table [onAdd]="onAddAccount" [cols]="cols" [isCreatable]="false"></app-table>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->

<ng-template #formModal let-modal>
    <div class="modal-header">
        <span class="modal-title">{{ formItemId == null ? "戶口新增" : "戶口修改" }}</span>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <form [formGroup]="form" (ngSubmit)="formSubmit()">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">戶口名稱*</label>
                <div class="col-sm-10">
                    <input class="form-control" formControlName="name">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">貨幣</label>
                <div class="col-sm-10">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox">
                        <label class="form-check-label">HKD</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox">
                        <label class="form-check-label">CNY</label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">戶口號碼</label>
                <div class="col-sm-10">
                    <input class="form-control" formControlName="account_number">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">戶口地址</label>
                <div class="col-sm-10">
                    <input class="form-control" formControlName="address">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">備注</label>
                <div class="col-sm-10">
                    <textarea class="form-control" formControlName="remarks"></textarea>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox">
                        <label class="form-check-label">現金戶口</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox">
                        <label class="form-check-label">手續費戶口</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox">
                        <label class="form-check-label">不參與計算</label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">指定客戶</label>
                <div class="col-sm-10">
                    <input class="form-control" formControlName="address">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <app-button
                [type]="'submit'"
                [block]="true"
                [loading]="isLoading"
            >
                {{ formItemId == null ? "新增" : "修改" }}
            </app-button>
            <!-- <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">新增</button> -->
        </div>
    </form>
</ng-template>