import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';
import { FieldConfig } from '@components/formfields/field-config.interface';
import { FormComponent } from '@components/form/form.component';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent {
  @ViewChild('form') public form: FormComponent;

  objClass: string = "configs";
  formDef: FieldConfig[];
  editObj;

  modal: NgbModalRef;
  active = 1;
  public isLoading = false;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {

    this.formDef = [
      {
        name: "fixedwiredbg",
        label: "預設固網寛頻計劃背景圖",
        type: "image",
      },
      {
        name: "fixedcellularbg",
        label: "預設家居5G寛頻計劃背景圖",
        type: "image",
      },
      {
        name: "mobilebg",
        label: "預設手機數據計劃背景圖",
        type: "image",
      },
      {
        name: "tvbg",
        label: "預設收費電視計劃背景圖",
        type: "image",
      },
    ];

    this.dataInit();
  }

  async dataInit() {
    await this.appService.configGet();
    this.editObj = this.appService.configs[0];

    this.formDef = [
      {
        name: "fixedwiredbg",
        label: "預設固網寛頻計劃背景圖",
        type: "image",
      },
      {
        name: "fixedcellularbg",
        label: "預設家居5G寛頻計劃背景圖",
        type: "image",
      },
      {
        name: "mobilebg",
        label: "預設手機數據計劃背景圖",
        type: "image",
      },
      {
        name: "tvbg",
        label: "預設收費電視計劃背景圖",
        type: "image",
      },
    ];
  }

  onSubmitted(result) {
    this.toastr.success("設定更新成功")
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
  }
}
